import React from "react";
import { translate } from "../../lib";

export const Modal = ({ onHide }) => {
  return (
    <div
      className="custom__modal-container"
      onClick={(e) => onHide + e.stopPropagation()}
    >
      <div className="custom__modal">
        <div className="custom__modal-header">
          <div className="modal__title">{translate.get("Qaydalar")}</div>
          <button
            onClick={onHide}
            className="close-btn feather feather-x"
          ></button>
        </div>
        <div className="custom__modal-body">
          <div className="modal_content">
            <div className="modal__info-content">
              <h4 className="text-center mb-4">{translate.get("Rules")}</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: translate.get("rules").replace(/\n/g, "<br />"),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
