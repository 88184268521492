import React from "react";
import { ComponentLoader, ComponentHeader } from "../../components";
import { lawItemsList } from "../../actions";
import { translate } from "../../lib";

export class Law extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      query: "",
      data: [],
    };
  }

  reLoad = async () => {
    this.setState({ isLoading: true });
    let { limit, offset } = this.state;
    let params = { limit, offset };
    let response = await lawItemsList(params);

    if (response) {
      this.setState({ isLoading: false });
      if (response.status === "success") {
        this.setState({ data: response.data });
      } else {
        this.setState({ data: [] });
      }
    }
  };

  async componentDidMount() {
    this.reLoad();
  }

  render() {
    let { isLoading, data, query } = this.state;
    return (
      <>
        <ComponentHeader
          title={translate.get("Items")}
          searchable={true}
          onSearch={(e) => {
            this.setState({ query: e.target.value });
          }}
          placeholder={"Search"}
          back={"/"}
          msg={true}
          grid={true}
        />
        {isLoading ? (
          <ComponentLoader />
        ) : (
          <div className="Law">
            <div className="law__container">
              {data
                .filter(
                  (l) =>
                    l.description.toLowerCase().includes(query.toLowerCase()) ||
                    l.item.toLowerCase().includes(query.toLowerCase())
                )
                .map((item, i) => (
                  <div
                    className={item.id === 99 ? "law__item-first" : "law__item"}
                    key={i}
                  >
                    <div className="law__item-header">
                      <div className="law__item-name">
                        <p>{translate.get(item.item)}</p>
                      </div>
                    </div>
                    <div className="law__item-content mb-2">
                      <p>{translate.get(item.description)}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </>
    );
  }
}
