import React from "react";
import { Card, ComponentHeader } from "../../components";
import { globalState, translate, user } from "../../lib";
import { Icon } from "../../components/Icon";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Balance, Cards, Number, Password, Point } from "./components";

export const Profile = () => {
  const history = useHistory();
  const [sidebar, setSidebar] = React.useState(false);
  const permission = globalState.getData()?.permissions;

  const TABS = [
    {
      path: "",
      title: "MyCards",
      icon: "card",
      permission: permission?.cards_view?.allow,
      component: (props) => <Cards />,
    },
    {
      path: "pay",
      title: `${translate.get("AddBalance")} (${
        user.getData()?.balance || 0
      } AZN)`,
      icon: "coins",
      permission: permission?.balance_view?.allow,
      component: (props) => <Balance />,
    },
    {
      path: "point",
      title: "Checkpoint",
      icon: "smartphone",
      permission: true,
      component: (props) => <Point />,
    },
    {
      path: "changenumber",
      title: "ChangeNumber",
      icon: "smartphone2",
      permission: true,
      component: (props) => <Number />,
    },
    {
      path: "changepassword",
      title: "ChangePassword",
      icon: "lock",
      permission: true,
      component: (props) => <Password />,
    },
    {
      title: "LogOut",
      icon: "exit",
      permission: true,
      component: (props) => <div />,
    },
  ];

  const active = history.location.pathname.split("/")[2];
  return (
    <Card className="mt-0">
      <Card.Header>
        <ComponentHeader
          title={translate.get("Profile")}
          back={"/"}
          msg={true}
          grid={true}
          profile={true}
          onOpenSidebar={() => {
            setSidebar(true);
          }}
        />
      </Card.Header>
      <div className="profile">
        <Card.Content>
          <div className={classNames("sidebar", { active: sidebar })}>
            <div className="user">
              <Icon icon="user-green" />
              <div className="user-detail">
                <div className="operator">
                  {
                    globalState
                      .get("operators")
                      .find((opr) => opr.id === user.getData().operator).name
                  }
                </div>
                <div className="number">{`0${user
                  .getData()
                  .msisdn.slice(3)}`}</div>
              </div>
            </div>
            <div className="tabs">
              {TABS.map((row, key) =>
                row.hasOwnProperty("path") ? (
                  row.permission && (
                    <Link
                      to={`/profile/${row.path}`}
                      key={key}
                      className={classNames("tab_item", {
                        active: row.path === active,
                      })}
                      onClick={() => setSidebar(false)}
                    >
                      <Icon icon={row.icon} className="is-24" />
                      <div className="title">{translate.get(row.title)}</div>
                    </Link>
                  )
                ) : (
                  <button
                    onClick={() => user.logOut()}
                    key={key}
                    className="tab_item"
                  >
                    <Icon icon={row.icon} className="is-24" />
                    <div className="title">{translate.get(row.title)}</div>
                  </button>
                )
              )}
            </div>
          </div>
          <div className="d_content">
            <div className="details">
              {TABS.find((row) => row.path === active)?.component()}
            </div>
          </div>
        </Card.Content>
      </div>
    </Card>
  );
};
