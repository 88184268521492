import React, { Fragment } from "react";
import { historyList } from "../../../actions";
import { toast, translate } from "../../../lib";
import { ComponentLoader } from "../../../components";

export const History = ({ modal, object, onClose = () => {}, setLocation }) => {
  const [state, setState] = React.useReducer(
    (prevState, newState) => ({ ...prevState, ...newState }),
    {
      loading: false,
      data: [],
    }
  );
  const loadData = async () => {
    setState({ loading: true });
    let response = await historyList({
      car_number: object.car_number,
      limit: 100,
    });
    if (response) {
      if (response.status === "success") {
        setState({ loading: false });
        setState({ data: response.data });
      } else {
        onClose();
        setTimeout(() => {
          toast.fire({
            icon: response.status,
            title: response.description,
          });
        }, 200);
      }
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <React.Fragment>
      <div
        style={{ padding: "1rem 0" }}
        ref={(el) => {
          if (el) {
            el.parentElement.style.padding = "0";
          }
        }}
      >
        {state.loading && (
          <div className="py-5">
            <ComponentLoader />
          </div>
        )}
        {!state.loading && (
          <div className="d-flex align-items-center px-3">
            <div className="car_info">
              <div className="img">
                <img src="/assets/icons/car.svg" alt="" />
              </div>
              <div className="title">{object.car_number}</div>
            </div>
          </div>
        )}
        <div className="custom-row border-0 px-2 mt-2">
          {state.data.map((item, i) => (
            <Fragment key={i}>
              {item.protocol_number ||
              item.fullname ||
              item.amount ||
              item.discount ||
              item.car_number ||
              item.total ||
              item.has_decision ||
              item.speed_max ||
              item.speed_real ||
              item.action_date ||
              item.decision_date ||
              item.address ||
              item.law_item ? (
                <React.Fragment>
                  <div className="custom-row" style={{ borderBottom: "none" }}>
                    {item.car_number ? (
                      <div className="custom-row__data">
                        <p className="data-title">{translate.get("Number")}:</p>
                        <p className="data-content">{item.car_number}</p>
                      </div>
                    ) : null}
                    {item.protocol_number ? (
                      <div className="custom-row__data">
                        <p className="data-title">
                          {translate.get("Protocol")}:
                        </p>
                        <p className="data-content">
                          {item.protocol_series + item.protocol_number}
                        </p>
                      </div>
                    ) : null}

                    {item.fullname.trim().length ? (
                      <div className="custom-row__data">
                        <p className="data-title">{translate.get("Fined")}:</p>
                        <p className="data-content">{item.fullname}</p>
                      </div>
                    ) : null}

                    {item.amount ? (
                      <div className="custom-row__data">
                        <p className="data-title">{translate.get("Fine")}:</p>
                        <p className="data-content">{`${item.amount} AZN`}</p>
                      </div>
                    ) : null}

                    {item.discount ? (
                      <div className="custom-row__data">
                        <p className="data-title">
                          {translate.get("Discount")}:
                        </p>
                        <p className="data-content">{`${item.discount} AZN`}</p>
                      </div>
                    ) : null}

                    {item.total ? (
                      <div className="custom-row__data">
                        <p className="data-title">
                          {translate.get("FinalAmount")}:
                        </p>
                        <p className="data-content">{`${item.total} AZN`}</p>
                      </div>
                    ) : null}

                    <div className="custom-row__data">
                      <p className="data-title">{translate.get("Status")}:</p>
                      <p className="data-content">{item.status.text}</p>
                    </div>

                    {item.speed_max ? (
                      <div className="custom-row__data">
                        <p className="data-title">
                          {translate.get("SpeedLimit")}:
                        </p>
                        <p className="data-content">{`${
                          item.speed_max
                        } ${translate.get("KmHour")}`}</p>
                      </div>
                    ) : null}

                    {item.speed_real ? (
                      <div className="custom-row__data">
                        <p className="data-title">
                          {translate.get("OverSpeed")}:
                        </p>
                        <p className="data-content">{`${
                          item.speed_real
                        } ${translate.get("KmHour")}`}</p>
                      </div>
                    ) : null}

                    {item.action_date ? (
                      <div className="custom-row__data">
                        <p className="data-title">{translate.get("Date")}:</p>
                        <p className="data-content">{item.action_date}</p>
                      </div>
                    ) : null}

                    {item.decision_date ? (
                      <div className="custom-row__data">
                        <p className="data-title">
                          {translate.get("DecisionDate")}:
                        </p>
                        <p className="data-content">{item.decision_date}</p>
                      </div>
                    ) : null}

                    {item.address ? (
                      <div className="custom-row__data">
                        <p className="data-title">
                          {translate.get("PlaceOfRegistration")}:
                        </p>
                        <p className="data-content">{item.address}</p>
                      </div>
                    ) : null}

                    {item.payment_date ? (
                      <div className="custom-row__data">
                        <p className="data-title">
                          {translate.get("PaymentDate2")}:
                        </p>
                        <p className="data-content">{item.payment_date}</p>
                      </div>
                    ) : null}

                    {item.law_item ? (
                      <div className="custom-row__data">
                        <p className="data-title">{translate.get("İXM")}:</p>
                        <p className="data-content">{item.law_item}</p>
                      </div>
                    ) : null}
                  </div>
                  <div className="custom-row pb-3">
                    <div className="d-flex w-100 flex-wrap">
                      {!!item.location && (
                        <button
                          className="custom-btn_view br px-3 mx-2"
                          onClick={() => {
                            modal.show("map");
                            setLocation([
                              item.location?.latitude,
                              item.location?.longitude,
                            ]);
                          }}
                        >
                          <i className="feather feather-map-pin mr-2" />
                          {translate.get("SeeOnMap")}
                        </button>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
            </Fragment>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};
