import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {MyCards} from '../layouts';
import {Add} from "../layouts/MyCards/components";

export class MyCardsContainer extends React.Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route exact path="/cards" component={MyCards} />
                    <Redirect to="/" />
                </Switch>
            </div>
        );
    }
}
