import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Point } from "../layouts";

export class PointContainer extends React.Component {
  render() {
    return (
        <div className='content' >
          <Switch>
            <Route exact path="/point" component={Point} />
            <Redirect to="/" />
          </Switch>
        </div>
    );
  }
}
