import React from 'react';

export const Icons = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 120 120" fill="none"
         xmlns="http://www.w3.org/2000/svg" className="btn-loading" >
      <g clipPath="url(#clip0_557_22399)">
        <path d="M0.9375 0H0V0.9375H0.9375V0Z" fill="white"/>
        <path
          d="M60.1219 86.025C74.4278 86.025 86.025 74.4278 86.025 60.1219C86.025 45.816 74.4278 34.2188 60.1219 34.2188C45.816 34.2188 34.2188 45.816 34.2188 60.1219C34.2188 74.4278 45.816 86.025 60.1219 86.025Z"
          fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M60.1219 17.3438C68.5826 17.3437 76.8533 19.8526 83.8881 24.5532C90.923 29.2537 96.4059 35.9347 99.6437 43.7514C102.881 51.5681 103.729 60.1693 102.078 68.4675C100.427 76.7656 96.3532 84.3879 90.3706 90.3706C84.3879 96.3532 76.7656 100.427 68.4675 102.078C60.1693 103.729 51.5681 102.881 43.7514 99.6437C35.9347 96.4059 29.2537 90.923 24.5532 83.8881C19.8526 76.8533 17.3438 68.5826 17.3438 60.1219C17.3438 48.7764 21.8507 37.8956 29.8732 29.8732C37.8956 21.8507 48.7764 17.3438 60.1219 17.3438ZM60.1219 24.7031C67.127 24.7031 73.9749 26.7804 79.7995 30.6723C85.6241 34.5641 90.1638 40.0958 92.8445 46.5677C95.5253 53.0396 96.2267 60.1612 94.8601 67.0317C93.4934 73.9023 90.1201 80.2133 85.1667 85.1667C80.2133 90.1201 73.9023 93.4934 67.0317 94.8601C60.1612 96.2267 53.0396 95.5253 46.5677 92.8445C40.0958 90.1638 34.5641 85.6241 30.6723 79.7995C26.7804 73.9749 24.7031 67.127 24.7031 60.1219C24.7031 50.7282 28.4347 41.7193 35.077 35.077C41.7193 28.4347 50.7282 24.7031 60.1219 24.7031Z"
              fill="white"/>
        <path
          d="M89.2968 16.3126C78.0155 8.68546 64.2214 5.71721 50.8015 8.02901C37.3815 10.3408 25.3757 17.7535 17.2968 28.7157L11.3436 24.5626C20.5224 12.0135 34.2141 3.51748 49.5337 0.864526C64.8533 -1.78842 80.6046 1.60891 93.4686 10.3407L89.2968 16.3126Z"
          fill="white"/>
        <path
          d="M83.186 107.216C95.4319 101.259 104.9 90.7975 109.607 78.0195C114.315 65.2416 113.899 51.138 108.445 38.6603L115.018 35.5813C121.296 49.8049 121.808 65.9102 116.446 80.5038C111.084 95.0975 100.266 107.04 86.2719 113.815L83.186 107.216Z"
          fill="white"/>
        <path
          d="M7.51721 56.472C6.55259 70.0555 10.879 83.4856 19.5911 93.9517C28.3032 104.418 40.7256 111.109 54.2586 112.624L53.6385 119.856C38.1813 118.182 23.9777 110.573 14.0204 98.6319C4.06303 86.6912 -0.870426 71.3515 0.259479 55.845L7.51721 56.472Z"
          fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_557_22399">
          <rect width="120" height="120" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  );
};
