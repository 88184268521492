import React from "react";
import classNames from "classnames";

export const Loading = ({ className }) => {
  return (
    <div className={classNames("loading", { [className]: className })}>
      <img alt="" src="/assets/images/loading.svg" />
    </div>
  );
};
