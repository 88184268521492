import { api } from "../lib";

export async function questionList(params) {
  return await api.get("questionList", params);
}

export async function questionAsk(params) {
  return await api.get("questionAsk", params);
}

export async function questionDelete(params) {
  return await api.get("questionDelete", params);
}

export async function questionReply(params) {
  return await api.get("questionReply", params);
}
