import React from "react";
import { Card, ComponentHeader, Icons, Warn } from "../../components";
import { globalState, toast, translate, user } from "../../lib";
import { Icon } from "../../components/Icon";
import { Popup } from "../../components/Modal";
import { Technical } from "./components";
import { useModal } from "../../components/hooks";
import { Link } from "react-router-dom";
import { carsAccidentList } from "../../actions";

export const Accident = () => {
  const modal = useModal();
  const [state, setState] = React.useReducer(
    (prevState, newState) => ({ ...prevState, ...newState }),
    {
      add_loading: false,
      loading: false,
      data: [],
      nv: "",
    }
  );

  const permission = globalState.getData()?.permissions;

  const onSubmit = async (e) => {
    e.preventDefault();
    setState({ add_loading: true });
    let response = await carsAccidentList({ vin: state.nv });
    if (response) {
      setState({ add_loading: false });
      if (response.status === "success") {
        setState({ data: response.data });
        modal.show("accident");
      } else {
        toast.fire({
          icon: response.status,
          title: response.description,
        });
      }
    }
  };

  return (
    <React.Fragment>
      <Popup
        show={modal.modals.includes("accident")}
        size="md"
        title="TechnicalFaults"
        onClose={() => modal.hide("accident")}
      >
        <Technical data={state.data} onClose={() => modal.hide("accident")} />
      </Popup>
      <Card>
        <Card.Header>
          <ComponentHeader
            title={translate.get("AccidentHistory")}
            back={"/"}
            msg={true}
            grid={true}
          />
        </Card.Header>
        <Card.Content>
          <div className="accident">
            <form onSubmit={onSubmit} className="mb-5">
              <div className="balance">
                <div className="d-flex flex-row align-items-center">
                  <div className="icon">
                    <Icon icon="coins" className="is-24" />
                  </div>
                  <div className="lh-18 ml-12">
                    <div className="coin">{user.getData()?.balance} AZN</div>
                    <span>{translate.get("Balance")}</span>
                  </div>
                </div>
                {permission?.balance_view?.allow && (
                  <Link to="/balance" className="custom-btn">
                    {translate.get("AddBalance")}
                  </Link>
                )}
              </div>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label className="custom-different-label">
                    {translate.get("VinCodeTitle")}
                  </label>
                  <input
                    required
                    maxLength="20"
                    type="text"
                    className="custom-different-input"
                    placeholder={"0123456789ABC"}
                    value={state.nv}
                    onChange={(e) => {
                      setState({ nv: e.target.value });
                    }}
                  />
                </div>

                <div className="col-md-12">
                  <button type="submit" className="custom-btn w-100 mt-1">
                    {state.add_loading ? <Icons /> : translate.get("Check")}
                  </button>
                </div>
              </div>
              <div className="mt-2" />
            </form>
            <Warn desc={"VinCodePayDesc"} br={"#F3EBCF"} warn={true} />
          </div>
        </Card.Content>
      </Card>
    </React.Fragment>
  );
};
