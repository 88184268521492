import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { translate } from "../../../lib";

export const Million = React.memo(({ modal, onClose }) => {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={modal}
      onHide={onClose}
    >
      <Modal.Header>
        <div className="d-flex justify-content-between align-items-center w-100">
          <h5 className="mb-0">{translate.get("PayWithMillionDesc")}</h5>
          <button className="close-btn feather feather-x" onClick={onClose} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="balance-modal row">
          {/* --- */}
          <div className="balance-modal--item">
            <div
              style={{
                color: "#6d6d6d",
                fontSize: 18,
                marginBottom: "1rem",
                textAlign: "center",
              }}
            >
              <b>1. </b>
              <span style={{ fontWeight: 300 }}>
                {translate.get("SelectPayCards")}
              </span>
            </div>
            <a href="https://smsradar.az/page/screens/4.jpeg" target="_blank">
              <img src="/assets/million-images/1.jpeg" alt="" />
            </a>
          </div>

          <div className="balance-modal--item">
            <div
              style={{
                color: "#6d6d6d",
                fontSize: 18,
                marginBottom: "1rem",
                textAlign: "center",
              }}
            >
              <b>2. </b>
              <span style={{ fontWeight: 300 }}>
                {translate.get("SelectSmsRadar")}
              </span>
            </div>
            <a href="https://smsradar.az/page/screens/5.jpeg" target="_blank">
              <img src="/assets/million-images/2.jpeg" alt="" />
            </a>
          </div>

          <div className="balance-modal--item">
            <div
              style={{
                color: "#6d6d6d",
                fontSize: 18,
                marginBottom: "1rem",
                textAlign: "center",
              }}
            >
              <b>3. </b>
              <span style={{ fontWeight: 300 }}>
                {translate.get("PhoneOperatorDesc")}
              </span>
            </div>
            <a href="https://smsradar.az/page/screens/1.jpeg" target="_blank">
              <img src="/assets/million-images/3.jpeg" alt="" />
            </a>
          </div>

          <div className="balance-modal--item">
            <div
              style={{
                color: "#6d6d6d",
                fontSize: 18,
                marginBottom: "1rem",
                textAlign: "center",
              }}
            >
              <b>4. </b>
              <span style={{ fontWeight: 300 }}>
                {translate.get("RightPhoneDesc")}
              </span>
            </div>
            <a href="https://smsradar.az/page/screens/2.jpeg" target="_blank">
              <img src="/assets/million-images/4.jpeg" alt="" />
            </a>
          </div>

          <div className="balance-modal--item">
            <div
              style={{
                color: "#6d6d6d",
                fontSize: 18,
                marginBottom: "1rem",
                textAlign: "center",
              }}
            >
              <b>5. </b>
              <span style={{ fontWeight: 300 }}>
                {translate.get("SelectCashDesc")}
              </span>
            </div>
            <a href="https://smsradar.az/page/screens/3.jpeg" target="_blank">
              <img src="/assets/million-images/5.jpeg" alt="" />
            </a>
          </div>

          <div className="balance-modal--item">
            <div
              style={{
                color: "#6d6d6d",
                fontSize: 18,
                marginBottom: "1rem",
                textAlign: "center",
              }}
            >
              <b>6. </b>
              <span style={{ fontWeight: 300 }}>
                {translate.get("EnterMoneyDesc")}
              </span>
            </div>
            <a href="https://smsradar.az/page/screens/3.jpeg" target="_blank">
              <img src="/assets/million-images/6.jpeg" alt="" />
            </a>
          </div>
          {/* --- */}

          <div className="w-100 px-3 mt-3">
            <button className="custom-btn btn-block" onClick={onClose}>
              {translate.get("Close")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
});
