export const cookie = {
  set(key, value, exdays = 0) {
    var date = new Date();
    date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + date.toUTCString();
    document.cookie = key + '=' + value + ';' + expires + ';path=/';
  },

  get(key) {
    var name = key + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var result = decodedCookie.split(';');
    for (var i = 0; i < result.length; i++) {
      var c = result[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  },

  del(key) {
    var date = new Date();
    date.setTime(date.getTime());
    var expires = 'expires=' + date.toUTCString();
    document.cookie = key + '=;' + expires + ';path=/';
  },

  check(key) {
    var result = this.get(key);
    if (result !== '') {
      return true;
    } else {
      return false;
    }
  },

  clear() {
    var multiple = document.cookie.split(';');
    for (var i = 0; i < multiple.length; i++) {
      var key = multiple[i].split('=');
      document.cookie =
        key[0] + ' =; expires = Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
  },

  delete() {
    var cookie = document.cookie.split(';');

    for (var i = 0; i < cookie.length; i++) {

      var chip = cookie[i],
        entry = chip.split("="),
        name = entry[0];

      document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
  }

};
