import React from "react";
import { Link } from "react-router-dom";
import { ComponentHeader } from "../../components";
import { translate } from "../../lib";

export class InsuranceInfo extends React.Component {
  render() {
    return (
      <div className="content">
        <div className="Insurance">
          <ComponentHeader
            title={translate.get("News")}
            back={"/"}
            msg={true}
            grid={true}
          />
          <div className="insurance__container">
            {/*<div className="insurance__partner-container mb-2 mb-md-3">*/}
            {/*  <div className="partner__item">*/}
            {/*    <img alt="" src="/assets/images/pasha.png" />*/}
            {/*  </div>*/}
            {/*  <div className="partner__item">*/}
            {/*    <img alt="" src="/assets/images/xalq.png" />*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="insurance__partner-about mb-4 mb-md-3">
              <p className="partner__about-text">{translate.get("NewsDesc")}</p>
              <p className="partner__about-text">
                {translate.get("NewsDesc2")}
              </p>
              <p className="partner__about-text">
                {translate.get("NewsDesc3")}
              </p>
              <p className="partner__about-text">
                <b>{translate.get("NewsDesc4")}</b>
              </p>
            </div>
            <div className="insurance__partner-call py-2">
              <div className="partner__call-image">
                <img alt="" src="/assets/images/call_bg.png" />
              </div>
              <Link
                target="blank"
                className="custom-link"
                to={{ pathname: "tel:+9940504445454" }}
              >
                (050) 444 54 54
              </Link>
              <Link
                target="blank"
                className="custom-link"
                to={{ pathname: "tel:+9940504444488" }}
              >
                (050) 444 44 88
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
