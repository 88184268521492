import { api } from "../lib";

export async function licenseList(params) {
  return await api.get("licenseList", params);
}
export async function licenseAdd(params) {
  return await api.get("licenseAdd", params);
}
export async function licenseDelete(params) {
  return await api.get("licenseDelete", params);
}
export async function licenseCheck(params) {
  return await api.get("licenseCheck", params);
}
export async function licenseInfo(params) {
  return await api.get("licenseInfo", params);
}
export async function licenseActivate(params) {
  return await api.get("licenseActivate", params);
}
export async function licenseDeactivate(params) {
  return await api.get("licenseDeactivate", params);
}
