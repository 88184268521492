import React from "react";
import {
  ComponentHeader,
  ComponentLoader,
  ScrollLoader,
} from "../../components";
import { messageList, messageDelete, messageMultiDelete } from "../../actions";
import { translate, toast, globalFunctions } from "../../lib";
import Swal from "sweetalert2";
import $ from "jquery";

export class Message extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isScrollLoading: false,

      offset: 0,
      limit: 100,

      data: [],
      count: 0,

      selected: [],
    };

    $(window).scroll(async () => {
      let scrollableHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      let percent = (
        (document.documentElement.scrollTop / scrollableHeight) *
        100
      ).toFixed();

      if (
        !this.state.isScrollLoading &&
        percent >= 100 &&
        this.state.offset + this.state.limit <= this.state.count
      ) {
        let skip = this.state.offset;
        await this.setState({ offset: (skip += 100) });

        this.setState({ isScrollLoading: true });
        let { limit, offset } = this.state;
        let params = { limit, offset };
        let response = await messageList(params);
        if (response) {
          this.setState({ isScrollLoading: false });
          if (response.status === "success") {
            let data = this.state.data;
            this.setState({ data: [...data, ...response.data] });
          } else {
            toast.fire({
              icon: "error",
              title: response.description,
            });
          }
        }
      }
    });
  }

  reLoad = async () => {
    this.setState({ isLoading: true });
    let { limit } = this.state;
    let params = { limit };
    let response = await messageList(params);

    if (response) {
      this.setState({ isLoading: false });
      if (response.status === "success") {
        this.setState({ data: response.data, count: response.count });
      } else {
        this.setState({ data: [] });
        toast.fire({
          icon: "info",
          title: response.description,
          buttonsStyling: false,
          showConfirmButton: true,
          confirmButtonClass: "custom-btn default-btn",
          timer: null,
        });
      }
    }
  };

  onDeleteAll = async () => {
    Swal.fire({
      title: translate.get("Verify"),
      text: translate.get("VerifyMessagesDesc"),
      icon: null,
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonClass: "custom-btn success-btn",
      cancelButtonClass: "custom-btn default-btn",
      confirmButtonText: translate.get("Confirm"),
      cancelButtonText: translate.get("Cancel"),
    }).then(async (res) => {
      if (res && res.value) {
        this.setState({
          isLoading: true,
        });
        let response = await messageMultiDelete({ ids: this.state.selected });
        if (response) {
          this.setState({
            isLoading: false,
            selected: [],
          });
          toast.fire({
            icon: response.status === "success" ? "success" : "error",
            title: translate.get("MessageSuccessfullyDeleted"),
          });
          if (response.status === "success") {
            this.reLoad();
            globalFunctions._reLoadMessages();
          }
        }
      }
    });
  };

  onSelect = (id) => {
    let selected = this.state.selected;
    if (selected.find((x) => x === id)) {
      selected = selected.filter((x) => x !== id);
    } else {
      selected.push(id);
    }

    this.setState({
      selected,
    });
  };

  onSelectAll = () => {
    this.setState({
      selected: !this.state.selected.length
        ? this.state.data.map((x) => x.id)
        : [],
    });
  };

  onDelete = async (id) => {
    Swal.fire({
      title: translate.get("Verify"),
      text: translate.get("VerifyMessageDesc"),
      icon: null,
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonClass: "custom-btn success-btn",
      cancelButtonClass: "custom-btn default-btn",
      confirmButtonText: translate.get("Confirm"),
      cancelButtonText: translate.get("Cancel"),
    }).then(async (res) => {
      if (res && res.value) {
        this.setState({
          isLoading: true,
        });
        let response = await messageDelete({ id });
        if (response) {
          this.setState({
            isLoading: false,
          });
          toast.fire({
            icon: response.status === "success" ? "success" : "error",
            title: translate.get("MessageSuccessfullyDeleted"),
          });
          if (response.status === "success") {
            this.reLoad();
            globalFunctions._reLoadMessages();
          }
        }
      }
    });
  };

  async componentDidMount() {
    this.reLoad();
  }

  render() {
    let { isLoading, isScrollLoading, data, selected } = this.state;
    return (
      <>
        <ComponentHeader
          title={translate.get("MessageBox")}
          back={"/"}
          deleted={true}
          allDelete={true}
          selected={selected}
          allDeleteFunc={() => this.onSelectAll()}
          grid={true}
          onMultiDelete={() => this.onDeleteAll()}
        />
        <div className="Message">
          <div className="message__container">
            {isLoading ? (
              <ComponentLoader />
            ) : (
              <div>
                {data.map((msg, i) => (
                  <div key={i} className="d-flex w-100">
                    <div>
                      <div className="round">
                        <input
                          type="checkbox"
                          id={`item${i}`}
                          onChange={() => this.onSelect(msg.id)}
                          checked={
                            this.state.selected.find((x) => x === msg.id)
                              ? true
                              : false
                          }
                        />
                        <label htmlFor={`item${i}`}></label>
                      </div>
                    </div>
                    <div
                      className="message__item w-100"
                      style={{
                        backgroundColor: this.state.selected.find(
                          (x) => x === msg.id
                        )
                          ? "#C3F8E6"
                          : "#fff",
                      }}
                      key={i}
                    >
                      <div className="message__item-content mb-2">
                        <div className="message_rows">
                          <span>{i + 1}</span>
                          <p>{msg.created_at}</p>
                        </div>
                      </div>
                      <div className="message__item-footer">
                        {/* <div className="message__item-image">
                                <img src="/assets/images/aze_flag.svg" alt="" />
                              </div>
                              <div className="message__item-name">
                                <p>{msg.id}</p>
                              </div> */}
                        <div>
                          <p className="mes">{msg.message}</p>
                        </div>
                        <div className="message__item-actions">
                          <button
                            className="message__delete feather feather-trash"
                            onClick={() => this.onDelete(msg.id)}
                          ></button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {isScrollLoading ? <ScrollLoader /> : null}
        </div>
      </>
    );
  }
}
