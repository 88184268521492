import React from "react";

import { Link } from "react-router-dom";
import {
  ComponentLoader,
  ComponentHeader,
  Sidebar,
  Spinner,
} from "../../components";
import { translate, globalFunctions, toast, globalState } from "../../lib";
import {
  carsList,
  carsActivate,
  carsDeactivate,
  carsDelete,
  carsInfo,
  carsCharge,
  carsInsuranceCheck,
  carsInsuranceCompanies,
  messageDelete,
  carsDeactivate1,
} from "../../actions";
import Swal from "sweetalert2";
import $ from "jquery";
import { Insurance } from "./Insurance";
import { useModal } from "../../components/hooks";
import { GetInsurance } from "./GetInsurance";
import { NotInsuranceModal } from "./NotInsuranceModal";
import { Activity } from "./Activity";
import classNames from "classnames";
import { Popup } from "../../components/Modal";
import { Technical } from "./Technical";
import { History } from "./History";
import { MyMap } from "./History/components";
import { Deaktive } from "./Actions";

export class CarList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isScrollLoading: false,
      isSearchLoading: false,
      getInsurance: false,
      isContentLoading: false,
      isContentLoadingID: null,
      id: false,
      modal: false,
      NoInsurance: false,
      activity: false,
      activityLoading: false,

      NoInsuranceTitle: "",

      limit: 50,
      offset: 0,
      keyword: "",

      url: "",
      urlStatus: "",
      cars: [],
      count: 0,
      insurances: [],

      car: null,

      screenWidth: window.innerWidth,
    };

    $(window).resize(() => {
      this.setState({ screenWidth: $(window).innerWidth() });
    });

    globalFunctions.set({
      refreshCarData: async (id, action) => {
        this.setState({ isLoading: true });
        let response = await carsInfo({ id, action });
        if (response) {
          this.setState({ isLoading: false });
          toast.fire({
            icon: response.status === "success" ? "success" : "error",
            title:
              response.status === "success"
                ? translate.get("UpdatedSuccess")
                : translate.get("errorOccuredPleaseAgain"),
          });
          if (response.status === "success") {
            let cars = this.state.cars;
            let car = response.data;
            console.log("car", car);
            car[`canRefresh_${action}`] = false;
            cars = cars.map((c) => {
              if (c.id === car.id) {
                c = { ...car, car_number: c.car_number };
              }
              return c;
            });
            this.setState({ car, cars });
          }
        }
      },
      refreshSubscriptionData: async (id, action) => {
        this.setState({ isLoading: true });
        let response = await carsCharge({ id });
        this.setState({ isLoading: false });
        toast.fire({
          icon: response.status === "success" ? "success" : "error",
          title:
            response.status === "success"
              ? translate.get("UpdatedSuccess")
              : response.description,
        });
        if (response.status === "success") {
          let cars = this.state.cars;
          let car = { ...this.state.car, ...response.data };
          car[`canRefresh_${action}`] = false;
          cars = cars.map((c) => {
            if (c.id === car.id) {
              c = { ...car, car_number: c.car_number };
            }
            return c;
          });
          this.setState({ car, cars });
        }
      },
      carsDeactivate: async (data) => {
        this.setState({ activityLoading: true });
        let response = await carsDeactivate({ id: data.id });
        if (response) {
          this.setState({ activityLoading: false });
        }
      },
      carsActivate: async (data) => {
        Swal.fire({
          title: translate.get("Verify"),
          text: translate.get("ConfirmActiveDesc"),
          icon: null,
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonClass: "custom-btn success-btn",
          cancelButtonClass: "custom-btn default-btn",
          confirmButtonText: translate.get("Confirm"),
          cancelButtonText: translate.get("Cancel"),
        }).then(async (res) => {
          if (res && res.value) {
            this.setState({
              isLoading: true,
            });

            let response = await carsActivate({ id: data.id });
            if (response) {
              this.setState({ isLoading: false });
              toast.fire({
                icon: response.status === "success" ? "success" : "error",
                title: response.description,
              });
              if (response.status === "success") {
                this.reLoad();
              }
            }
          }
        });
      },
      deleteCar: async (id) => {
        Swal.fire({
          title: translate.get("Verify"),
          text: translate.get("VerifyCarDesc"),
          icon: null,
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonClass: "custom-btn success-btn",
          cancelButtonClass: "custom-btn default-btn",
          confirmButtonText: translate.get("Confirm"),
          cancelButtonText: translate.get("Cancel"),
        }).then(async (res) => {
          if (res && res.value) {
            this.setState({
              isLoading: true,
            });

            let respoonse = await carsDelete({ id });
            if (respoonse) {
              this.setState({ isLoading: false });
              toast.fire({
                icon: respoonse.status === "success" ? "success" : "error",
                title: respoonse.description,
              });
              if (respoonse.status === "success") {
                this.reLoad();
              }
            }
          }
        });
      },
      onExport: async () => {
        console.log(this.state);
      },
    });
  }

  loadAgreement = async (data) => {
    let response = await carsInsuranceCheck({ id: data?.id });
    if (response) {
      this.setState({ urlStatus: response.status });
      if (response.status === "success") {
        this.setState({ url: response.data?.url });
      } else {
        this.setState({ url: response.description });
      }
    }
  };

  loadInsurances = async () => {
    let response = await carsInsuranceCompanies();
    if (response.status === "success") {
      this.setState({ insurances: response.data });
    } else {
      this.setState({ insurances: [] });
    }
  };

  reLoad = async () => {
    this.setState({ isLoading: true });

    let { limit, offset, car, keyword } = this.state;
    let params = { limit, offset, keyword };
    let cars = await carsList(params);
    if (cars) {
      this.setState({ isLoading: false });
      if (cars.status === "success") {
        this.setState({ cars: cars.data, count: cars.count });
        if (car) {
          let currentCar = cars.data.find((c) => c.id === car.id);
          this.setState({ car: currentCar });
        }
      }
    }
  };

  onRefresh = async () => {
    this.setState({ isContentLoading: true });
    let response = await carsInfo({ id: this.state.car?.id });
    if (response) {
      this.setState({
        isContentLoading: false,
        isContentLoadingID: this.state.car?.id,
      });
      if (response.status === "success") {
        this.setState({ car: response.data });
      }
    }
  };

  componentDidMount() {
    this.reLoad();
    this.loadInsurances();
  }

  onGet = (data) => {
    if (data) {
      this.setState({ car: data });
      this.loadAgreement(data);
    } else {
      this.setState({ car: null });
    }
  };

  onSearch = async (e) => {
    if (e.target.value.trim().length >= 5) {
      await this.setState({ keyword: e.target.value });

      this.setState({ isSearchLoading: true });
      let { keyword, limit } = this.state;
      let params = { keyword, limit };
      let cars = await carsList(params);
      if (cars) {
        this.setState({ isSearchLoading: false });
        if (cars.status === "success") {
          this.setState({ cars: cars.data, count: cars.count });
        } else {
          this.setState({ cars: [], count: 0 });
          toast.fire({
            toast: true,
            position: "top",
            icon: "error",
            title: cars.description,
            timer: 1000,
          });
        }
      }
    } else if (e.target.value.trim().length === 0) {
      await this.setState({ keyword: "" });

      this.reLoad();
    }
  };

  onScroll = async () => {
    let elmnt = document.querySelector(".sidebar__item-container");
    let scrollableHeight = elmnt.scrollHeight - elmnt.clientHeight;
    let percent = ((elmnt.scrollTop / scrollableHeight) * 100).toFixed();

    if (
      !this.state.isScrollLoading &&
      percent >= 100 &&
      this.state.offset + this.state.limit <= this.state.count
    ) {
      let skip = this.state.offset;
      await this.setState({ offset: (skip += 50) });

      this.setState({ isScrollLoading: true });
      let { limit, offset, keyword } = this.state;
      let params = { limit, offset, keyword };
      let response = await carsList(params);
      if (response) {
        this.setState({ isScrollLoading: false });
        if (response.status === "success") {
          let cars = this.state.cars;
          this.setState({ cars: [...cars, ...response.data] });
        } else {
          toast.fire({
            icon: "error",
            title: response.description,
          });
        }
      }
    }
  };

  render() {
    let {
      isLoading,
      isScrollLoading,
      isSearchLoading,
      cars,
      car,
      screenWidth,
      modal,
      url,
      urlStatus,
      getInsurance,
      NoInsurance,
      activity,
      activityLoading,
      insurances,
    } = this.state;

    let insuranceCompany =
      insurances.find((x) => x.id === car?.car_insurer) || "";
    return (
      <div className="content">
        <ComponentHeader
          title={translate.get("MyCars")}
          searchable={true}
          onSearch={this.onSearch}
          placeholder="SearchDesc"
          back={"/"}
          msg={true}
          grid={true}
        />
        <Insurance
          onClose={() => this.setState({ modal: false })}
          addModal={modal}
          car={car}
          refresh={() => this.reLoad()}
        />
        <GetInsurance
          onClose={() => this.setState({ getInsurance: false })}
          getInsurance={getInsurance}
        />
        <NotInsuranceModal
          onClose={() => this.setState({ NoInsurance: false })}
          NoInsurance={NoInsurance}
          url={url}
        />
        <Activity
          onClose={() => this.setState({ activity: false })}
          activity={activity}
          activityLoading={activityLoading}
          car={car}
        />
        {isLoading ? (
          <ComponentLoader />
        ) : (
          <div className="Cars">
            <div className="cars__content">
              <Sidebar
                searchable={true}
                car={car}
                data={cars}
                type={"cars"}
                onGet={this.onGet}
                onScroll={this.onScroll}
                onSearch={this.onSearch}
                isScrollLoading={isScrollLoading}
                isSearchLoading={isSearchLoading}
                url={url}
                openModal={() => this.setState({ modal: true })}
                getInsuranceModal={() => this.setState({ getInsurance: true })}
                IfNoInsurance={() => this.setState({ NoInsurance: true })}
                urlStatus={urlStatus}
                activityModal={() => {
                  this.setState({ activity: true });
                  globalFunctions._carsDeactivate(car);
                }}
                insuranceCompany={insuranceCompany}
              />
              {screenWidth > 1024 ? (
                <div className="cars__container">
                  <div className="cars__content-main">
                    {car ? (
                      <ContentCars
                        object={car}
                        url={url}
                        openModal={() => this.setState({ modal: true })}
                        getInsuranceModal={() =>
                          this.setState({ getInsurance: true })
                        }
                        IfNoInsurance={() =>
                          this.setState({ NoInsurance: true })
                        }
                        activityModal={() => {
                          this.setState({ activity: true });
                          globalFunctions._carsDeactivate(car);
                        }}
                        urlStatus={urlStatus}
                        insuranceCompany={insuranceCompany}
                        onRefresh={this.onRefresh}
                        onRefreshCars={this.reLoad}
                        isContentLoading={this.state.isContentLoading}
                        isContentLoadingID={this.state.isContentLoadingID}
                      />
                    ) : cars.length === 0 ? (
                      <div className="d-flex flex-column align-items-center justify-content-center h-100 pt-5">
                        <img src="/assets/images/noCar.svg" alt="" />
                        <p
                          style={{
                            color: "#16A085",
                            margin: "2em 0",
                          }}
                        >
                          {translate.get("NoCarAdded")}..
                        </p>
                        <Link to="/cars/add">
                          <button className="custom-btn ">
                            {translate.get("NewCar")}
                          </button>
                        </Link>
                      </div>
                    ) : (
                      <div className="d-flex flex-column align-items-center justify-content-center h-100 pt-5 mt-5">
                        <img src="/assets/images/noCar.svg" alt="" />
                        <p
                          style={{
                            color: "#16A085",
                            margin: "1em 0",
                          }}
                        >
                          {translate.get("SelectCar")}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              ) : cars.length === 0 ? (
                <div className="cars__container">
                  <div className="cars__content-main">
                    <div className="d-flex flex-column align-items-center justify-content-center h-100 pt-5">
                      <img
                        src="/assets/images/noCar.svg"
                        alt=""
                        style={{
                          objectFit: "contain",
                          width: screenWidth < 359 ? "100%" : "",
                        }}
                      />
                      <p
                        style={{
                          color: "#16A085",
                          margin: "2em 0",
                          textAlign: "center",
                        }}
                      >
                        {translate.get("NoCarAdded")}..
                      </p>
                      <Link to="/cars/add">
                        <button className="custom-btn ">
                          {translate.get("NewCar")}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export const ContentCars = (props) => {
  let {
    object,
    url,
    openModal,
    getInsuranceModal,
    urlStatus,
    IfNoInsurance,
    activityModal,
    insuranceCompany,
    isContentLoadingID,
    isContentLoading,
  } = props;
  const [location, setLocation] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [hash, setHash] = React.useState("");
  const modal = useModal();

  const onDeaktive = async () => {
    Swal.fire({
      title: translate.get("Verify"),
      text: translate.get("ConfirmDeActive"),
      icon: null,
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonClass: "custom-btn success-btn",
      cancelButtonClass: "custom-btn default-btn",
      confirmButtonText: translate.get("Confirm"),
      cancelButtonText: translate.get("Cancel"),
    }).then(async (res) => {
      if (res && res.value) {
        setLoading(true);
        let response = await carsDeactivate1({ data: { id: object.id } });
        if (response) {
          setLoading(false);
          toast.fire({
            toast: true,
            position: "bottom-left",
            icon: response.status,
            title: response.data.description,
          });
          if (response.status === "success") {
            setHash(response.data.verify_hash);
            modal.show("deaktive");
          }
        }
      }
    });
  };

  const permission = globalState.getData()?.permissions;
  return (
    <React.Fragment>
      <Popup
        show={modal.modals.includes("technical")}
        size="md"
        title="TechnicalFaults"
        onClose={() => modal.hide("technical")}
      >
        <Technical id={object.id} onClose={() => modal.hide("technical")} />
      </Popup>
      <Popup
        show={modal.modals.includes("map")}
        size="lg"
        title="SeeOnMap"
        onClose={() => modal.hide("map")}
        setLocation={(map) => setLocation(map)}
      >
        <MyMap location={location} active={true} />
      </Popup>
      <Popup
        show={modal.modals.includes("history")}
        size="lg"
        title="PaymentDate"
        onClose={() => modal.hide("history")}
      >
        <History
          object={object}
          onClose={() => modal.hide("history")}
          setLocation={setLocation}
          modal={modal}
        />
      </Popup>
      <Popup
        show={modal.modals.includes("deaktive")}
        size="md"
        title="DeActive"
        onClose={() => modal.hide("deaktive")}
      >
        <Deaktive
          object={object}
          _hash={hash}
          onClose={() => modal.hide("deaktive")}
          onRefresh={() => {
            props.onRefreshCars();
          }}
        />
      </Popup>
      <div className="d-flex align-items-center">
        <div className="car_info">
          <div className="img">
            <img src="/assets/icons/car.svg" alt="" />
          </div>
          <div className="title">{object.car_number}</div>
          <Link
            to={`/protocol?id=${object.id}&number=${object.car_number}`}
            className="custom-btn d-flex align-items-center fs-14 ml-auto"
          >
            {translate.get("ProtocolCars")}
            <i className="feather feather-chevron-right fs-20 ml-2" />
          </Link>
          {isContentLoadingID !== object.id && (
            <button
              className={classNames("custom-btn-light h-100 ml-3", {
                "core-rotating-icon": isContentLoading,
              })}
              onClick={props.onRefresh}
            >
              <i className="feather feather-refresh-cw" />
            </button>
          )}
        </div>
      </div>
      {object.tech_id || object.created_date || object.mark || object.year ? (
        <div className="custom-row">
          {object.tech_id ? (
            <div className="custom-row__data">
              <p className="data-title">
                {translate.get("TechnicalPassNumber")}:
              </p>
              <p className="data-content">
                {object.tech_id}
                {object.tech_changed ? (
                  <Link
                    to={{ pathname: `cars/edit/${object.id}` }}
                    className={`link btn btn-link`}
                  >
                    ({translate.get("Change")})
                  </Link>
                ) : null}
              </p>
            </div>
          ) : null}

          {object.created_date ? (
            <div className="custom-row__data">
              <p className="data-title">{translate.get("RegisterDate")}:</p>
              <p className="data-content">{object.created_date}</p>
            </div>
          ) : null}

          {object.mark ? (
            <div className="custom-row__data">
              <p className="data-title">{translate.get("BrendModel")}:</p>
              <p className="data-content">{object.mark}</p>
            </div>
          ) : null}

          {object.year ? (
            <div className="custom-row__data">
              <p className="data-title">{translate.get("GraduationYear")}:</p>
              <p className="data-content">{object.year}</p>
            </div>
          ) : null}
        </div>
      ) : null}

      {!!object.show_tech && (
        <div className="custom-row">
          <div className="custom-row__data justify-content-between">
            <p className="data-title">{translate.get("Insurance2")}:</p>
            <p className="data-content">
              {translate.get(
                object.insurance === 1
                  ? "Exist"
                  : object.insurance === 2
                  ? "no_checked"
                  : "is_no"
              )}
              {object.show_insurance ? (
                !object.hasOwnProperty("canRefresh_insurance") ||
                object.canRefresh_insurance ? (
                  <button
                    className={`link btn btn-link pr-1 ${object.id}`}
                    onClick={() =>
                      globalFunctions._refreshCarData(object.id, "insurance")
                    }
                  >
                    ({translate.get("Update")})
                  </button>
                ) : null
              ) : null}
            </p>
          </div>

          <div className="custom-row__data">
            <p className="data-title">{translate.get("Insurance3")}:</p>
            <button
              className={`link btn btn-link pl-0`}
              style={{ fontSize: "0.9em" }}
              onClick={getInsuranceModal}
            >
              {translate.get("FromUs")}
            </button>
          </div>

          <div className="custom-row__data">
            <p className="data-title">{translate.get("InsuranceDesc2")}:</p>
            {urlStatus === "success" ? (
              <a
                className={`link btn btn-link pl-0`}
                style={{ fontSize: "0.9em" }}
                target="_blank"
                href={url}
              >
                {translate.get("Download")}
              </a>
            ) : (
              <button
                className={`link btn btn-link pl-0`}
                style={{ fontSize: "0.9em" }}
                onClick={IfNoInsurance}
              >
                {translate.get("Download")}
              </button>
            )}
          </div>

          {!!object.insurance_date && (
            <div className="custom-row__data">
              <p className="data-title">{translate.get("InsuranceEndDate")}:</p>
              <p className="data-content">
                {object.insurance_date}
                <button
                  className={`link btn btn-link pl-0 ml-2`}
                  style={{ fontSize: "0.9em" }}
                  onClick={openModal}
                >
                  ({translate.get("Change")})
                </button>
              </p>
            </div>
          )}

          {object.show_insurance ? (
            <>
              {object.car_insurer ? (
                <div className="custom-row__data">
                  <p className="data-title">
                    {translate.get("InsuranceCompany")}:
                  </p>
                  <p className="data-content">
                    {insuranceCompany?.title ||
                      `${translate.get("Loading")}...`}
                  </p>
                </div>
              ) : null}

              {object.car_insurance_doc ? (
                <div className="custom-row__data">
                  <p className="data-title">
                    {translate.get("InsuranceContract")}:
                  </p>
                  <p className="data-content">{object.car_insurance_doc}</p>
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      )}

      {object.has_arrest ? (
        <div className="custom-row">
          <div className="custom-row__data">
            <p className="data-title">{translate.get("PrisonDesc")}:</p>
            <p className="data-content">
              {object.has_arrest}
              {object.show_arrest ? (
                !object.hasOwnProperty("canRefresh_arrest") ||
                object.canRefresh_arrest ? (
                  <button
                    className={`link btn btn-link ${object.id}`}
                    onClick={() =>
                      globalFunctions._refreshCarData(object.id, "arrest")
                    }
                  >
                    ({translate.get("Update")})
                  </button>
                ) : null
              ) : null}
            </p>
          </div>
        </div>
      ) : null}

      <div className="custom-row">
        {object.show_tech ? (
          <div className="custom-row__data">
            <p className="data-title">
              {translate.get("TechnicalReviewEndDate")}:
            </p>
            <p className="data-content">
              {object.tech_exp_date}
              {object.show_tech ? (
                !object.hasOwnProperty("canRefresh_tech") ||
                object.canRefresh_tech ? (
                  <button
                    className={`link btn btn-link ${object.id}`}
                    onClick={() =>
                      globalFunctions._refreshCarData(object.id, "tech")
                    }
                  >
                    ({translate.get("Update")})
                  </button>
                ) : null
              ) : null}
            </p>
          </div>
        ) : null}

        {/*{object.subscription_exp_date ? (*/}
        <div className="custom-row__data">
          <p className="data-title">{translate.get("SmsRadarEndDate")}:</p>
          <p className="data-content">
            {object.subscription_exp_date}
            {!object.hasOwnProperty("canRefresh_charge") ||
            object.canRefresh_charge ? (
              <button
                className={`link btn btn-link ${object.id}`}
                onClick={() =>
                  globalFunctions._refreshSubscriptionData(object.id, "charge")
                }
              >
                ({translate.get("Update")})
              </button>
            ) : null}
          </p>
        </div>
        {/*) : null}*/}
      </div>
      {/*) : null}*/}
      <div className="d-flex flex-md-nowrap flex-wrap mt-2">
        <button
          onClick={() => modal.show("technical")}
          className="custom-btn br my-2 mx-2 new_btn"
        >
          {translate.get("TechnicalFaults")}
          {permission?.show_new_icons?.allow && (
            <span className="dash__link-badge badge">
              {translate.get("New")}
            </span>
          )}
        </button>
        <button
          onClick={() => modal.show("history")}
          className="custom-btn warning-btn br my-2 mx-2"
        >
          {translate.get("PaymentDate")}
        </button>
        {object.status !== 0 ? (
          <button
            onClick={onDeaktive}
            className="custom-btn danger-btn br my-2 mx-2"
            disabled={loading}
          >
            {loading ? <Spinner /> : translate.get("DeActive")}
          </button>
        ) : (
          <button
            onClick={() => globalFunctions._carsActivate(object)}
            className="custom-btn success-btn br my-2 mx-2"
          >
            {translate.get("Active")}
          </button>
        )}
      </div>
    </React.Fragment>
  );
};
