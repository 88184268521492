import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Balance } from "../layouts";

export class BalanceContainer extends React.Component {
  render() {
    return (
      <div className="content">
        <Switch>
          <Route exact path="/balance" component={Balance} />
          <Redirect to="/" />
        </Switch>
      </div>
    );
  }
}
