import React from "react";
import { globalState, translate } from "../../../lib";
import { Modal } from "react-bootstrap";
import { ComponentLoader } from "../../../components";

export const Add = ({ addModal, onClose, reload }) => {
  const [loading, setLoading] = React.useState(true);

  setTimeout(() => {
    setLoading(false);
  }, 1000);

  window.addEventListener(
    "message",
    (event) => {
      console.log("message @@", event);
      if (event?.data === "closewindow") {
        onClose();
        reload();
      }
    },
    false
  );

  return (
    <div className="content">
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={addModal}
        onHide={onClose}
      >
        <Modal.Header>
          <div className="d-flex justify-content-between align-items-center w-100">
            <h5 className="mb-0">{translate.get("Add")}</h5>
            <button className="close-btn feather feather-x" onClick={onClose} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div
            ref={(el) => {
              if (el) {
                el.parentElement.style.padding = "0";
              }
            }}
          />
          {loading && <ComponentLoader />}
          <iframe
            src={`https://smsradar.az/apixxx/card/add?lang=az&token=${globalState.get(
              "token"
            )}&`}
            width="100%"
            height="500"
            allowFullScreen
            style={{ border: "none" }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
