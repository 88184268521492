import React, { useReducer, useRef, useEffect, useCallback } from "react";
import { ComponentLoader, ComponentHeader, Warn } from "../../components";
import { translate, api, toast } from "../../lib";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

let alertTimeout;

function Exam() {
  const answerTimeout = useRef(null);
  const [setAlertData] = useReducer((a, b) => ({ ...a, ...b }), {
    type: null,
    description: null,
  });

  const [state, setState] = useReducer((a, b) => ({ ...a, ...b }), {
    loading: true,
    status: null,
    code: null,
    description: null,
    data: {
      id: null,
      start_date: null,
      end_date: null,
      questions: [],
      status: 0,
    },
    question: null,
    answers: {},
  });

  const getNextQuestion = (index) => {
    const question = state.data.questions[index];
    if (question) {
      if (question.status) {
        return getNextQuestion(index + 1);
      }
      return question;
    } else {
      return false;
    }
  };

  const setAnswer = async (answer) => {
    if (answerTimeout.current) {
      clearTimeout(answerTimeout.current);
    }
    const response = await api.get("examSetAnswer", {
      exam_id: state.data.id,
      question_id: state.question.id,
      answer_id: answer,
    });
    if (response) {
      setState({
        type: response.status,
        description: response.description,
      });
      // if(response.data) {
      //   setState({
      //     data: response.data,
      //     correctDescription: selectedQuestionData.description
      //   });
      // }
      let answers = state.answers;
      answers[state.question.id] = answer;
      setState({
        answers,
      });

      setState({
        code: response.success_code
          ? response.success_code
          : response.error_code,
      });

      if (response.success_code === 5011) {
        let selectedQuestionData = response.data.questions.find(
          (x) => state.question.id === x.id
        );
        Swal.fire({
          title: null,
          text: selectedQuestionData.description,
          icon: null,
          buttonsStyling: false,
          showCancelButton: false,
          confirmButtonClass: "custom-btn success-btn",
          cancelButtonClass: "custom-btn default-btn",
          confirmButtonText: translate.get("Close"),
        }).then(async (res) => {
          if (res && res.value) {
            let index = state.data.questions.indexOf(state.question);
            let question = getNextQuestion(index + 1);
            if (question) {
              answerTimeout.current = setTimeout(() => {
                setState({
                  question,
                  type: null,
                  correctDescription: null,
                  description: null,
                });
              }, 100);
            }
          }
        });
      }
      if (
        response.success_code !== 5011 ||
        response.error_code === 5006 ||
        response.error_code === 5005 ||
        response.error_code === 5004
      ) {
        let index = state.data.questions.indexOf(state.question);
        let question = getNextQuestion(index + 1);
        if (question) {
          answerTimeout.current = setTimeout(() => {
            setState({
              question,
              type: null,
              correctDescription: null,
              description: null,
            });
          }, 2000);
        }
      }
    }
  };

  const getStatus = useCallback(async (params = {}) => {
    let newState = {
      loading: false,
    };
    const response = await api.get("examStatus", params);
    if (response) {
      if (response.error_code === 5012) {
        newState = {
          ...newState,
          code: 5001,
          type: response.status,
          description: response.description,
          data: {
            id: null,
            start_date: null,
            end_date: null,
            questions: [],
            status: 0,
          },
          question: null,
          answers: {},
        };
      } else {
        let question = response.data?.questions.find((row) => !row.status);
        newState = {
          ...newState,
          code: response.error_code,
          type: response.status,
          description: response.description,
          data: response.data,
          question,
          answers:
            response.data && response.data.questions
              ? response.data.questions
                  .filter((row) => row.user_answer)
                  .reduce((obj, row) => {
                    return {
                      ...obj,
                      [row.id]: row.user_answer,
                    };
                  }, {})
              : {},
        };
      }
    }

    setState(newState);
  }, []);

  const subscribe = useCallback(async () => {
    setState({
      loading: true,
      type: null,
      code: null,
      description: null,
    });
    const response = await api.get("examSubscribe", {});
    if (response) {
      const statusResponse = await api.get("examStatus", {});
      if (statusResponse) {
        setState({
          loading: false,
          code: statusResponse?.error_code === 5009 ? 5009 : 5001,
          type: statusResponse?.error_code === 5009 ? "error" : response.status,
          description: response.description,
        });
      }
    }
  }, []);

  const unsubscribe = useCallback(async () => {
    Swal.fire({
      title: null,
      text: translate.get("VerifySubsDesc"),
      icon: null,
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonClass: "custom-btn success-btn",
      cancelButtonClass: "custom-btn default-btn",
      confirmButtonText: translate.get("Confirm"),
      cancelButtonText: translate.get("Cancel"),
    }).then(async (res) => {
      if (res && res.value) {
        setState({
          loading: true,
          type: null,
          code: null,
          description: null,
        });
        const response = await api.get("examUnsubscribe", {});
        if (response) {
          setState({
            loading: false,
            code: 5007,
            type: response.status,
            description: response.description,
          });
        }
      }
    });
  }, []);

  const start = async () => {
    setState({
      loading: true,
      type: null,
      code: null,
      description: null,
    });
    const response = await api.get("examStart", {});
    if (response) {
      if (response.status === "success") {
        setState({
          loading: false,
          type: "success",
          // description: response.description,
          code: response.error_code,
          data: response.data,
          question: response.data?.questions.find((row) => !row.status),
          answers: {},
        });
      } else {
        setState({
          loading: false,
          type: "error",
          description: response.description,
          answers: {},
        });
      }
    }
  };

  const finish = async () => {
    Swal.fire({
      title: null,
      text: translate.get("EndExamDesc"),
      icon: null,
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonClass: "custom-btn success-btn",
      cancelButtonClass: "custom-btn default-btn",
      confirmButtonText: translate.get("Confirm"),
      cancelButtonText: translate.get("Cancel"),
    }).then(async (res) => {
      if (res && res.value) {
        setState({
          loading: true,
        });
        const response = await api.get("examFinish", {});
        if (response) {
          setState({
            loading: false,
            code: 5001,
            type: response.status,
            description: response.description,
          });
        }
      }
    });
  };

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  useEffect(() => {
    return () => {
      if (alertTimeout) {
        clearTimeout(alertTimeout);
      }
      if (answerTimeout.current) {
        clearTimeout(answerTimeout.current);
      }
    };
  }, []);

  const { code, description, type, loading, question, answers, data } = state;

  if (loading) {
    return (
      <Container>
        <ComponentLoader />
      </Container>
    );
  }

  if (code === 5007 || code === 5009) {
    return (
      <Container
        header={() => (
          <div className="component__header d-flex _jc-between flex-row">
            <Link to={"/"} className="go_back">
              <img
                src="/assets/images/back_arrow.svg"
                alt=""
                style={{ marginTop: "-3px" }}
              />
            </Link>
            <div className="component__header-title pt-1">
              <h1>{translate.get("Exams")}</h1>
            </div>
            <div />
          </div>
        )}
      >
        {code === 5009 && (
          <div
            style={{ margin: "0 auto" }}
            className="d-flex _center flex-column"
          >
            <img src="/assets/images/exam_draw.svg" alt="" className="mb-3" />
            <Alert
              type={type}
              description={code === 5009 ? "ExamNoBalanceDesc" : null}
            />
            <button
              className="custom-outline_btn-dark br mt-1"
              style={{ padding: "8px 20px" }}
              onClick={subscribe}
            >
              {translate.get("ActivateTheSub")}
            </button>
          </div>
        )}
        {code === 5007 && (
          <div className="exam__content">
            <div className="exam-right_side">
              {/*{code !== 5001 && <Alert type={type} description={description} />}*/}
              <div className="d-flex flex-column align-items-center justify-content-center h-100 pt-3">
                <Alert
                  type={type}
                  description={code === 5009 ? "ExamNoBalanceDesc" : null}
                />
                <img
                  src="/assets/images/exam_draw.svg"
                  alt=""
                  style={{ width: "50%" }}
                />
                <div className="w-75 mt-4">
                  <Warn desc={"ExamActiveDesc"} warn={true} br={"#F3EBCF"} />
                </div>
                <button
                  className="custom-outline_btn-dark br mt-3"
                  style={{ padding: "8px 20px" }}
                  onClick={subscribe}
                >
                  {translate.get("ActivateTheSub")}
                </button>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    color: "#AEAEAE",
                    fontSize: 14,
                    margin: "2em 0",
                  }}
                >
                  {translate.get("Note")}: {translate.get("OnlineExamDesc")}
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            position: "absolute",
            bottom: 30,
            left: 0,
            width: "100%",
            textAlign: "center",
            color: "#808080",
            fontSize: 15,
          }}
        ></div>
      </Container>
    );
  } else if (code === 5001 || code === 5006 || code === 5010) {
    return (
      <Container
        header={() => (
          <div className="component__header d-flex _jc-between flex-row">
            <Link to={"/"} className="go_back">
              <img
                src="/assets/images/back_arrow.svg"
                alt=""
                style={{ marginTop: "-3px" }}
              />
            </Link>
            <div className="component__header-title pt-1">
              <h1>{translate.get("OnlineExam")}</h1>
            </div>
            <div className="component__header-left pl-3 align-items-center text-white">
              <button
                className="custom-outline_btn ml-2"
                onClick={unsubscribe}
                style={{ height: "2rem" }}
              >
                Deaktiv et
              </button>
            </div>
          </div>
        )}
      >
        <div className="exam__content">
          <div className="exam-right_side">
            {/*{code !== 5001 && <Alert type={type} description={description} />}*/}
            <div className="w-sm-50 mb-3">
              {code !== 5001 && (
                <Warn err={true} desc={description} br="#FFBDBD" />
              )}
            </div>
            <h5 className="text-center">
              {translate.get("WelcomeOnlineExam")}
            </h5>
            <p className="text-center">{translate.get("QuestionsDesc")}..</p>
            <div className="d-flex flex-column align-items-center justify-content-center h-100 pt-2">
              <img
                src="/assets/images/exam_draw.svg"
                alt=""
                style={{ width: "70%" }}
              />
              <button onClick={start} className="custom-btn br mt-4 w-75">
                {translate.get("StartExam")}
              </button>
            </div>
          </div>
        </div>
      </Container>
    );
  } else if (data && data.id && data.end_date) {
    return (
      <Container
        header={() => (
          <div className="component__header d-flex _jc-between flex-row">
            <Link to={"/"} className="go_back">
              <img
                src="/assets/images/back_arrow.svg"
                alt=""
                style={{ marginTop: "-3px" }}
              />
            </Link>
            <div className="component__header-title pt-1 dd_exam">
              <h1>{translate.get("OnlineExam")}</h1>
            </div>
            <div className="component__header-left pl-3 align-items-center text-white">
              <img src="/assets/images/time.svg" alt="" />
              <p>
                <Timer
                  endDate={data.end_date * 1000}
                  goToResult={() =>
                    setState({
                      type: "error",
                      description: translate.get("ExamFailed"),
                      code: 5010,
                      answers: {},
                      question: null,
                    })
                  }
                />
              </p>
              <button
                className="custom-outline_btn ml-2"
                onClick={finish}
                style={{ height: "2rem" }}
              >
                {translate.get("IsEnd")}
              </button>
            </div>
          </div>
        )}
      >
        <div className="exam__content">
          <div className="exam-right_side">
            {question?.avatar && (
              <img
                src={question.avatar}
                alt=""
                className="rounded mb-4 question-image"
              />
            )}
            <div className="question-answers">
              <Alert type={type} description={description} />

              {question?.title && (
                <div className="mb-4">
                  <b>{question.title}</b>
                </div>
              )}
              {question?.answers && (
                <div>
                  <div
                    className="d-flex flex-wrap mb-4"
                    style={{ marginLeft: "-.9em" }}
                  >
                    {question.answers.map((row, i) => (
                      <div key={i} className="mb-2 col-sm-6">
                        <b>
                          {{ 1: "A", 2: "B", 3: "C", 4: "D", 5: "E" }[row.id]})
                        </b>{" "}
                        {row.title}
                      </div>
                    ))}
                  </div>
                  <div className="answer-btn-group form-row flex-row flex-nowrap justify-content-between justify-content-md-start">
                    {question.answers.map((row, i) => (
                      <div key={i} className="col-md-auto">
                        <button
                          key={`${question.id}_${row.id}_${i}`}
                          onClick={() => setAnswer(row.id)}
                          className={`d-block fw-700 w-100${
                            answers[question.id] &&
                            answers[question.id] === row.id
                              ? ` active`
                              : ``
                          }`}
                        >
                          {{ 1: "A", 2: "B", 3: "C", 4: "D", 5: "E" }[row.id]}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    );
  } else {
    // return (
    //   <Container>
    //     <Alert type={type} description={description} />
    //   </Container>
    // );
  }
}

function secToTime(value) {
  let hours = Math.floor(value / 3600);
  let minutes = Math.floor(value / 60);
  let seconds = value % 60;

  if (value >= 360000) {
    return (
      (hours < 10 ? "0" + hours : hours) +
      ":" +
      (minutes < 10 ? "0" + minutes : minutes) +
      ":" +
      (seconds < 10 ? "0" + seconds : seconds)
    );
  } else {
    return (
      (minutes < 10 ? "0" + minutes : minutes) +
      ":" +
      (seconds < 10 ? "0" + seconds : seconds)
    );
  }
}

function Timer({ endDate, goToResult }) {
  let date = new Date();
  let end_date = new Date(endDate);

  let start = Math.round(end_date.getTime() / 1000 - date.getTime() / 1000);
  const [seconds, setSeconds] = React.useState(start);

  useEffect(() => {
    let interval = null;

    interval = setInterval(() => {
      setSeconds((sec) => sec - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  useEffect(() => {
    if (seconds <= 0) {
      goToResult();
    }
  });

  return secToTime(seconds);
}

const Container = ({
  className,
  header = () => (
    <ComponentHeader
      className="col"
      title={translate.get("OnlineExam")}
      back={"/"}
    />
  ),
  ...props
}) => {
  return (
    <>
      {header()}
      <div
        className={`exam__content ${className ? className : ``}`}
        {...props}
      />
    </>
  );
};

const Alert = ({
  type = null,
  description = null,
  className = null,
  correctDescription = false,
}) => {
  if (!type || !description) {
    return null;
  }

  return (
    <div
      className={`alert text-center ${className && ` ` + className}`}
      data-type={type}
    >
      {translate.get(description)}
      {/*{correctDescription && <div className="mt-3">{correctDescription}</div>}*/}
    </div>
  );
};

export { Exam };
