import React from "react";
import { ComponentLoader, ComponentHeader } from "../../components";
import { translate, toast } from "../../lib";
import { accountChangePassword } from "../../actions";

export class Password extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      oldpassword: "",
      newpassword: "",
      confirmnewpassword: ""
    };
  }

  onSubmit = async e => {
    e.preventDefault();
    let { oldpassword, newpassword, confirmnewpassword } = this.state;
    if (newpassword === confirmnewpassword) {
      this.setState({ isLoading: true });
      let params = { oldpassword, newpassword };
      let response = await accountChangePassword(params);
      if (response) {
        this.setState({ isLoading: false });
        toast.fire({
          icon: response.status === "success" ? "success" : "error",
          title: response.description
        });
        if (response.status === "success") {
          this.setState({
            oldpassword: "",
            newpassword: "",
            confirmnewpassword: ""
          });
        }
      }
    } else {
      toast.fire({
        icon: "error",
        title: translate.get("Zehmet olmasa yeni sifreni duzgun tesdiqleyin")
      });
    }
  };

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  render() {
    let {
      isLoading,
      oldpassword,
      newpassword,
      confirmnewpassword
    } = this.state;

    return (
      <>
        {isLoading ? (
          <ComponentLoader />
        ) : (
          <div
            ref={(el) => {
              if (el) {
                el.parentElement.style.background = "#fff";
              }
            }}
          >
          <div className="Password">
            <ComponentHeader
                title={translate.get("Şifrə dəyiş")}
                back={'/'}
                msg={true}
                grid={true}
            />
            <div className="password__content">
              <form onSubmit={this.onSubmit}>
                <div className="row">
                  <div className="col-md-12 mb-3 mt-2">
                    <label className="custom-different-label">
                      {translate.get("Köhnə şifrə")}
                    </label>
                    <input
                      required
                      type="password"
                      className="custom-different-input"
                      placeholder={translate.get("Daxil edin")}
                      value={oldpassword}
                      onChange={e => {
                        this.setState({ oldpassword: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="custom-different-label">
                      {translate.get("Yeni şifrə")}
                    </label>
                    <input
                      required
                      type="password"
                      className="custom-different-input"
                      placeholder={translate.get("Daxil edin")}
                      value={newpassword}
                      onChange={e => {
                        this.setState({ newpassword: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="custom-different-label">
                      {translate.get("Təkrar yeni şifrə")}
                    </label>
                    <input
                      required
                      type="password"
                      className="custom-different-input mb-2"
                      placeholder={translate.get("Daxil edin")}
                      value={confirmnewpassword}
                      onChange={e => {
                        this.setState({ confirmnewpassword: e.target.value });
                      }}
                    />
                    {newpassword &&
                    confirmnewpassword &&
                    confirmnewpassword !== newpassword ? (
                      <label className="custom-different-label-error">
                        {translate.get("Şifrənin düzgünlüyunə əmin olun")}
                      </label>
                    ) : null}
                  </div>
                  <div className="col-md-12">
                    <button type="submit" className="custom-btn w-100">
                      {translate.get("Təsdiqlə")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          </div>
        )}
      </>
    );
  }
}
