import React from "react";
import { toast, translate } from "../../../lib";
import { protocolAssign } from "../../../actions";
import { Spinner } from "../../../components";

export const AssignProtocol = ({
  id,
  refresh = () => {},
  onClose = () => {},
}) => {
  const [loading, setLoading] = React.useState(false);
  const [params, setParams] = React.useState({
    serial_number: "",
    pin: "",
    tax_id: "",
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let response = await protocolAssign({
      protocol_id: id,
      pin: params.pin,
      dl_series: params.serial_number.slice(0, 2),
      dl_number: params.serial_number.slice(2, 8),
      tax_id: params.tax_id,
    });
    if (response) {
      setLoading(false);
      toast.fire({
        icon: response.status === "success" ? "success" : "error",
        title: response.description,
      });
      if (response.status === "success") {
        refresh();
        onClose();
      }
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label className="custom-different-label">
            {translate.get("SerialNumberTitle")}
          </label>
          <input
            required
            type="text"
            maxLength={8}
            className="custom-different-input"
            placeholder={translate.get("SerialNumberPlaceholder")}
            value={params.serial_number}
            onChange={(e) => {
              setParams({ ...params, serial_number: e.target.value });
            }}
          />
        </div>
        <div className="form-group">
          <label className="custom-different-label">
            {translate.get("PinCodeTitle")}
          </label>
          <input
            required
            type="text"
            maxLength={7}
            className="custom-different-input"
            placeholder={translate.get("PinCodePlaceholder")}
            value={params.pin}
            onChange={(e) => {
              setParams({ ...params, pin: e.target.value });
            }}
          />
        </div>
        <div className="form-group">
          <label className="custom-different-label">
            {translate.get("TinTitle")}
          </label>
          <input
            required
            type="text"
            maxLength={10}
            className="custom-different-input"
            placeholder={translate.get("TinPlaceholder")}
            value={params.tax_id}
            onChange={(e) => {
              setParams({ ...params, tax_id: e.target.value });
            }}
          />
        </div>
        <button className="custom-btn btn-block" disabled={loading}>
          {loading ? <Spinner /> : translate.get("Send")}
        </button>
        <hr style={{ marginTop: "1.5rem" }} />
        <p style={{ color: "#777", fontSize: 16 }} className="mb-0">
          <b>{translate.get("Note")}:</b> {translate.get("ProtocolRequestDesc")}
        </p>
      </form>
    </div>
  );
};
