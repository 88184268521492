import React from "react";

export class Loader extends React.Component {
  render() {
    return (
      <div className="Loader">
        <img alt="" src="/assets/images/loading.svg" />
        {/*<p>{translate.get("Yüklənir...")}</p>*/}
      </div>
    );
  }
}

export class ComponentLoader extends React.Component {
  render() {
    return (
      <div className="ComponentLoader">
        <img alt="" src="/assets/images/loading2.svg" />
        {/*<p>{translate.get("Yüklənir...")}</p>*/}
      </div>
    );
  }
}

export class ScrollLoader extends React.Component {
  render() {
    return (
      <div className="ScrollLoader" style={{ zIndex:6 }} >
        <img alt="" src="/assets/images/loading2.svg" />
      </div>
    );
  }
}