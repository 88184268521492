import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Header, Footer, Loader } from "../../components";
import { user, globalFunctions, globalState, translate } from "../../lib";
import { accountSettings, translations } from "../../actions";
import { API_ROUTES } from "../../config";
import {
  DashboardContainer,
  AccountContainer,
  MessageContainer,
  PasswordContainer,
  BalanceContainer,
  NumberContainer,
  CarContainer,
  LawContainer,
  QuestionContainer,
  ProtocolContainer,
  LicenseContainer,
  PointContainer,
  InsuranceContainer,
  ExamContainer,
  HistoryContainer,
  MyCardsContainer,
  PayContainer,
  MyProfile,
  Navimax,
} from "../../containers";
import { Accident } from "../Accident";

export class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };

    globalFunctions.set({
      setApiRoutes: (data) => {
        globalState.set({
          apiRoutes: {
            ...globalState.get("apiRoutes"),
            ...data,
          },
        });
      },
    });
  }

  async componentDidMount() {
    await globalFunctions._setApiRoutes(API_ROUTES);
    try {
      let response = await accountSettings();
      let responseTranslations = await translations();
      if (response) {
        if (response.status === "success") {
          if (responseTranslations) {
            if (responseTranslations.status === "success") {
              globalState.set({
                account_data: response.account_data,
                permissions: response.permissions,
                lang_data: responseTranslations.data?.lang_data,
                default_lang: response.lang,
              });
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
    }

    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 200);
  }

  render() {
    let { isLoading } = this.state;
    const permissions = globalState.getData()?.permissions;

    return (
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="App">
            <Header />
            <section
              className="app__main"
              style={{
                backgroundImage: "url('/assets/images/back_icons.svg')",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <div className="custom-container">
                {/*<Switch>*/}
                {/*  <Route path="/pay" component={PayContainer}/>*/}
                {/*</Switch>*/}
                {user.isAuth() ? (
                  <Switch>
                    <Route exact path="/" component={DashboardContainer} />
                    <Route
                      exact
                      path="/messages"
                      component={MessageContainer}
                    />
                    <Route
                      exact
                      path="/password"
                      component={PasswordContainer}
                    />
                    {permissions?.balance_view?.allow && (
                      <Route
                        exact
                        path="/balance"
                        component={BalanceContainer}
                      />
                    )}
                    <Route exact path="/number" component={NumberContainer} />
                    <Route path="/cars" component={CarContainer} />
                    <Route exact path="/law" component={LawContainer} />
                    <Route path="/exam" component={ExamContainer} />
                    <Route path="/faq" component={QuestionContainer} />
                    <Route path="/protocol" component={ProtocolContainer} />
                    <Route path="/licenses" component={LicenseContainer} />
                    <Route path="/point" component={PointContainer} />
                    <Route path="/insurance" component={InsuranceContainer} />
                    <Route path="/profile" component={MyProfile} />
                    <Route path="/navimax" component={Navimax} />
                    {permissions?.accident_history_view?.allow && (
                      <Route path="/accidenthistory" component={Accident} />
                    )}
                    <Route path="/history" component={HistoryContainer} />
                    {permissions?.cards_view?.allow && (
                      <Route path="/cards" component={MyCardsContainer} />
                    )}
                    {permissions?.balance_view?.allow && (
                      <Route path="/pay" component={PayContainer} />
                    )}
                    <Redirect to="/" />
                  </Switch>
                ) : (
                  <Switch>
                    <Route path="/" component={AccountContainer} />
                  </Switch>
                )}
              </div>
            </section>
            <Footer />
          </div>
        )}
      </>
    );
  }
}
