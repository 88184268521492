import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {Pay} from '../layouts';

export class PayContainer extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/pay" component={Pay}/>
        <Redirect to="/"/>
      </Switch>
    );
  }
}