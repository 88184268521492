import React from "react";
import { Link } from "react-router-dom";
import { ComponentLoader, Warn } from "../../../../components";
import { translate, toast, globalState } from "../../../../lib";
import { balanceAddByAzCard, accountInfo } from "../../../../actions";
import { Million, Pay } from "./components";

export class Balance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      balance: 0,
      pay: false,
      code: "",
      modal: false,
    };
  }

  componentDidMount() {
    this.load();
  }

  load = async () => {
    this.setState({ isLoading: true });

    let token = globalState.get("token");
    let response = await accountInfo({ token });
    if (response && response.status === "success") {
      this.setState({ isLoading: false, balance: response.data.balance });
    } else {
      toast.fire({
        icon: "error",
        title: response.description,
      });
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    let { code } = this.state;
    let params = { code };
    let response = await balanceAddByAzCard(params);
    if (response) {
      this.setState({ isLoading: false });
      toast.fire({
        icon: response.status === "success" ? "success" : "error",
        title: response.description,
      });
      if (response.status === "success") {
        setTimeout(() => {
          window.location = "/";
        }, 2000);
      }
    }
  };

  render() {
    let { isLoading, code, balance, modal } = this.state;
    const permissions = globalState.getData()?.permissions;
    const permission = globalState.getData()?.permissions?.azcard_view?.allow;

    return this.state.pay ? (
      <Pay />
    ) : (
      <React.Fragment>
        <Million
          modal={modal}
          onClose={() => this.setState({ modal: false })}
        />
        {isLoading ? (
          <ComponentLoader className="h-100" />
        ) : (
          <div className="Balance">
            <div className="balance__content">
              <div className="row">
                {permissions?.million_view?.allow && (
                  <div className="col-md-auto">
                    <div
                      className="balance-card"
                      onClick={() => this.setState({ modal: true })}
                    >
                      <img src="/assets/icons/milli.png" alt="milli" />
                      <p className="mb-0 mt-2">
                        {translate.get("PayWithMillion")}
                      </p>
                    </div>
                  </div>
                )}
                {permissions?.cardpay_view?.allow && (
                  <div className="col-md-auto">
                    <div
                      onClick={() => this.setState({ pay: true })}
                      className="balance-card"
                    >
                      <img src="/assets/icons/viza.png" alt="visa" />
                      <p className="mb-0 mt-2">
                        {translate.get("PayWithCard")}
                      </p>
                    </div>
                  </div>
                )}
              </div>

              {!!permission && (
                <div className="form-content">
                  <form onSubmit={this.onSubmit}>
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        <label className="custom-different-label">
                          {translate.get("EnterAzcardCode")}
                        </label>
                        <input
                          required
                          maxLength="20"
                          type="text"
                          className="custom-different-input mb-2"
                          placeholder={"123456789012"}
                          value={code}
                          onChange={(e) => {
                            this.setState({ code: e.target.value });
                          }}
                        />
                        <Link
                          target="blank"
                          to={{
                            pathname: "https://azcard.az/",
                          }}
                          className="balance__question"
                        >
                          {translate.get("GetAzCardPassword")}
                        </Link>
                      </div>
                      <div className="col-md-12">
                        <button type="submit" className="custom-btn mt-1 w-100">
                          {translate.get("Download")}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}

              <div className="warn_balance mt-4">
                <Warn desc={"BalanceDesc"} warn={true} br={"#F3EBCF"} />
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
