import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { CarList, CarAdd, CarEdit } from "../layouts";

export class CarContainer extends React.Component {
  render() {
    return (
        <Switch>
            <Route exact path="/cars" component={CarList} />
            <Route exact path="/cars/add" component={CarAdd} />
            <Route exact path="/cars/edit/:id" component={CarEdit} />
            <Redirect to="/" />
        </Switch>
    );
  }
}
