import React from "react";
import {
  ComponentLoader,
  ComponentHeader,
  Card,
  Loading,
} from "../../components";
import { carsInfo, carsEdit } from "../../actions";
import { translate, toast, globalState } from "../../lib";

export class CarEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      value: "",
      id: props.match.params.id,
    };
  }

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    let { id, value } = this.state;

    let params = { id, tech_number: value };

    let response = await carsEdit(params);
    if (response) {
      this.setState({ isLoading: false });

      toast.fire({
        icon: response.status === "success" ? "success" : "error",
        title: response.description,
      });
      if (response.status === "success") {
        setTimeout(() => {
          window.location = "/";
        }, 1000);
      }
    }
  };

  async componentDidMount() {
    this.setState({ isLoading: true });

    let { id } = this.state;

    let response = await carsInfo({ id });
    if (response && response.status === "success") {
      this.setState({ car_number: response.data.car_number, isLoading: false });
    } else {
      toast.fire({
        icon: "error",
        title: response.description,
      });

      setTimeout(() => {
        window.location = "/";
      }, 1000);
    }
  }

  render() {
    let { isLoading, car_number, number } = this.state;

    return (
      <Card>
        <Card.Header>
          <ComponentHeader
            title={translate.get("ChangeTech")}
            back={"/cars"}
            msg={true}
            grid={true}
          />
        </Card.Header>
        <Card.Content>
          {isLoading && <Loading />}
          <div className="CarEdit">
            <div className="car-add__content">
              <form onSubmit={this.onSubmit} className="mb-5">
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <label className="custom-different-label">
                      {translate.get("CarrNumber")}:<b>{car_number}</b>
                    </label>
                  </div>
                  <div className="col-md-12 mb-2">
                    <label className="custom-different-label">
                      {translate.get("SerialNumberTitle")}
                    </label>
                  </div>
                  <div className="col-md-12">
                    <input
                      required
                      maxLength="8"
                      type="text"
                      className="custom-different-input"
                      placeholder={translate.get("SerialNumberPlaceholder")}
                      value={number}
                      onChange={(e) => {
                        this.setState({ value: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col-md-12 mt-3">
                    <button type="submit" className="custom-btn br w-100">
                      {translate.get("AcceptIt")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Card.Content>
      </Card>
    );
  }
}
