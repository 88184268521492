import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { ComponentLoader, ComponentHeader, Sidebar } from "../../components";
import { AssignProtocol, Modal } from "./components";
import {
  translate,
  globalFunctions,
  functions,
  toast,
  globalState,
} from "../../lib";
import {
  carsList,
  protocolList,
  showModal,
  hideModal,
  carsMinList,
} from "../../actions";
import $ from "jquery";
import { Component } from "react";
import { Print } from "./components/Print";
import { Popup } from "../../components/Modal";
import { useModal } from "../../components/hooks";
import { MyMap } from "./components";

class ProtocolList extends React.Component {
  constructor(props) {
    super(props);

    this.printRef = [];

    this.state = {
      isLoading: false,
      isScrollLoading: false,
      isContentLoading: false,
      isSearchLoading: false,

      modals: [],

      keyword: "",
      limit: 50,
      offset: 0,

      count: 0,
      cars: [],
      car: null,
      car_number: null,

      protocols: [],
      protocolText: "",
      protocolID: null,
      carID: null,

      isExport: false,
      isExportBtn: false,

      screenWidth: window.innerWidth,
    };

    globalFunctions.set({
      getCarInfo: () => {
        return this.state.car;
      },
    });

    $(window).resize(() => {
      this.setState({ screenWidth: $(window).innerWidth() });
    });
  }

  reLoad = async () => {
    this.setState({ isLoading: true });

    let { limit, offset } = this.state;
    let params = { limit, offset };
    let cars = await carsList(params);
    if (cars) {
      this.setState({ isLoading: false });
      if (cars.status === "success") {
        this.setState({ cars: cars.data, count: cars.count });
      } else {
        this.setState({ cars: [] });
      }
    }
  };

  componentDidMount() {
    this.reLoad();
    this.onTest();
  }

  onTest = () => {
    const paramsUrl = new URLSearchParams(this.props?.location?.search);
    const car_number = paramsUrl.get("number");
    const id = paramsUrl.get("id");
    if (car_number) {
      this.onGet({ car_number });
      this.setState({ carID: id });
    }
  };

  onGet = async (data) => {
    if (data) {
      this.setState({ car_number: data.car_number });
      if (data !== this.state.car) {
        this.setState({ car: data, isContentLoading: true });
        let response = await protocolList({ car_number: data.car_number });
        if (response) {
          this.setState({
            isContentLoading: false,
            car_number: data.car_number,
          });
          if (response.status === "success") {
            this.setState({ protocols: response.data, protocolText: "" });
          } else {
            this.setState({
              protocolText: response.description,
              protocols: [],
            });
          }
          let { protocols, protocolText } = this.state;
          globalFunctions._setSidebarState({ protocols, protocolText });
        }
      }
    } else {
      this.setState({ car: null });
    }
  };

  onScroll = async () => {
    let elmnt = document.querySelector(".sidebar__item-container");
    let scrollableHeight = elmnt.scrollHeight - elmnt.clientHeight;
    let percent = ((elmnt.scrollTop / scrollableHeight) * 100).toFixed();

    if (
      !this.state.isScrollLoading &&
      percent >= 100 &&
      this.state.offset + this.state.limit <= this.state.count
    ) {
      let skip = this.state.offset;
      await this.setState({ offset: (skip += 50) });

      this.setState({ isScrollLoading: true });
      let { limit, offset } = this.state;
      let params = { limit, offset };
      let response = await carsList(params);
      if (response) {
        this.setState({ isScrollLoading: false });
        if (response.status === "success") {
          let cars = this.state.cars;
          this.setState({ cars: [...cars, ...response.data] });
        } else {
          toast.fire({
            icon: "error",
            title: response.description,
          });
        }
      }
    }
  };

  onSearch = async (e) => {
    if (e.target.value.trim().length >= 5) {
      await this.setState({ keyword: e.target.value });

      this.setState({ isSearchLoading: true });
      let { keyword, limit } = this.state;
      let params = { keyword, limit };
      let cars = await carsList(params);
      if (cars) {
        this.setState({ isSearchLoading: false });
        if (cars.status === "success") {
          this.setState({ cars: cars.data, count: cars.count });
        } else {
          this.setState({ cars: [], count: 0 });
          toast.fire({
            toast: true,
            position: "top",
            icon: "error",
            title: cars.description,
            timer: 1000,
          });
        }
      }
    } else if (e.target.value.trim().length === 0) {
      await this.setState({ keyword: "" });

      this.reLoad();
    }
  };

  onExport = async (exportUrl) => {
    this.setState({
      isExport: true,
      isExportBtn: false,
      exportUrl,
    });
  };

  render() {
    let {
      isLoading,
      isScrollLoading,
      isContentLoading,
      isSearchLoading,
      modals,
      cars,
      protocols,
      protocolText,
      protocolID,
      screenWidth,
      isExport,
      exportUrl,
      isExportBtn,
    } = this.state;

    return (
      <div className="content">
        <ComponentHeader
          title={translate.get("Protocols")}
          searchable={true}
          onSearch={this.onSearch}
          placeholder={translate.get("SearchDesc")}
          back={"/"}
          msg={true}
          grid={true}
        />
        {isLoading ? (
          <ComponentLoader />
        ) : (
          <div className="Protocols">
            {functions.in_array("confirm", modals) ? (
              <Modal
                protocolID={protocolID}
                onClose={() => {
                  hideModal.bind(this)("confirm");
                }}
              />
            ) : null}
            <div className="protocols__content">
              <Sidebar
                // searchable={true}
                tab="protocol"
                data={cars}
                type={"protocols"}
                onGet={this.onGet}
                onShow={() => {
                  showModal.bind(this)("confirm");
                }}
                setID={(id) => {
                  this.setState({ protocolID: id });
                }}
                onExport={this.onExport.bind(this)}
                // onSearch={this.onSearch}
                onScroll={this.onScroll}
                isExport={isExport}
                isExportBtn={isExportBtn}
                isScrollLoading={isScrollLoading}
                isContentLoading={isContentLoading}
                isSearchLoading={isSearchLoading}
                printRef={this.printRef}
                protocolID={this.state.car_number}
                carID={this.state.carID}
              />
              {screenWidth > 1024 ? (
                <div className="protocols__container">
                  <div className="protocols__content-main">
                    {isContentLoading ? (
                      <ComponentLoader />
                    ) : isExport ? (
                      <ContentExport
                        onEnd={() =>
                          this.setState({
                            isExport: false,
                            isExportBtn: true,
                          })
                        }
                        exportUrl={exportUrl}
                        isExportBtn={isExportBtn}
                      />
                    ) : protocols.length ? (
                      <ContentProtocols
                        object={protocols}
                        protocolID={this.state.car_number}
                        setID={(id) => {
                          this.setState({ protocolID: id });
                        }}
                        onShow={() => {
                          showModal.bind(this)("confirm");
                        }}
                        printRef={this.printRef}
                        onGet={this.onGet}
                      />
                    ) : protocolText ? (
                      <div className="protocol__empty-content">
                        <img alt="" src="/assets/images/alert-big.svg" />
                        <p className="protocol__empty-content-text">
                          {protocolText}
                        </p>
                      </div>
                    ) : cars.length === 0 ? (
                      <div className="d-flex flex-column align-items-center justify-content-center h-100 pt-5">
                        <img src="/assets/images/noCar.svg" alt="" />
                        <p style={{ color: "#16A085", margin: "2em 0" }}>
                          {translate.get("NoCarAdded")}..
                        </p>
                        <Link to="/cars/add">
                          <button className="custom-btn ">
                            {translate.get("AddCar")}
                          </button>
                        </Link>
                      </div>
                    ) : (
                      <div className="d-flex flex-column align-items-center justify-content-center h-100 pt-5 mt-5">
                        <img src="/assets/images/noCar.svg" alt="" />
                        <p style={{ color: "#16A085", margin: "1em 0" }}>
                          {translate.get("SelectCar")}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              ) : cars.length === 0 ? (
                <div className="protocols__container">
                  <div className="protocols__content-main">
                    <div className="d-flex flex-column align-items-center justify-content-center h-100 pt-5">
                      <img
                        src="/assets/images/noCar.svg"
                        alt=""
                        style={{
                          objectFit: "contain",
                          width: screenWidth < 359 ? "100%" : "",
                        }}
                      />
                      <p
                        style={{
                          color: "#16A085",
                          margin: "2em 0",
                          textAlign: "center",
                        }}
                      >
                        {translate.get("NoCarAdded")}..
                      </p>
                      <Link to="/cars/add">
                        <button className="custom-btn ">
                          {translate.get("AddCar")}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(ProtocolList);

class ContentExport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      data: [],

      isExportBtn: this.props.isExportBtn,
    };

    this.loadedRef = React.createRef(0);
    this.interval = false;
  }

  async fetchDataByIndex(index) {
    let data = this.state.data[index];
    if (data) {
      const res = await protocolList({
        car_number: data.number,
      });

      this.setState({
        data: this.state.data.map((x) => {
          if (x.number === data.number) {
            return {
              ...x,
              loaded: !!res?.status,
              count: (res?.data || []).length,
            };
          } else {
            return x;
          }
        }),
      });

      let next = this.state.data[index + 1];
      if (next) {
        this.timeout = setTimeout(() => {
          this.fetchDataByIndex(index + 1);
        }, 500);
      } else {
        // window.open(this.props.exportUrl, "Export");
        // window.location.href = this.props.exportUrl;
        this.props.onEnd();
      }
    }
  }

  async componentDidMount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    const response = await carsMinList();
    if (response?.data) {
      let data = response?.data.map((number) => ({
        number,
        loaded: false,
        count: 0,
      }));

      this.setState({
        isLoading: false,
        data,
        total: data.length,
      });

      if (data.length > 0) {
        await this.fetchDataByIndex(0);
      }
    }
  }

  componentWillUnmount() {
    this.props.onEnd();
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    const { isLoading, data } = this.state;
    return (
      <>
        {!isLoading ? (
          <div>
            {data.map((row) => (
              <div
                className="d-flex flex-row justify-content-between align-items-center pb-2 mb-2 mt-2 border-bottom"
                key={row.number}
              >
                <span style={{ fontSize: 18 }}>{row.number}</span>
                <div className="d-flex flex-row align-items-center">
                  {row.loaded ? (
                    <>
                      <span>{row.count}</span>
                      {/* <i
                        className="feather feather-check text-success"
                        style={{ fontSize: 20 }}
                      /> */}
                    </>
                  ) : (
                    <>
                      <span className="mr-2">{translate.get("Loading")}..</span>
                      <img
                        src="/assets/images/component-loader.svg"
                        width={20}
                        alt=""
                      />
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <ComponentLoader />
        )}
      </>
    );
  }
}

export const ContentProtocols = (props) => {
  const [payInfo, setPayInfo] = React.useState(null);
  const [location, setLocation] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [print, setPrint] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(null);
  const modal = useModal();
  const onLoad = () => {
    setLoading(false);
  };

  return (
    <React.Fragment>
      <Popup
        show={modal.modals.includes("map")}
        size="lg"
        title="SeeOnMap"
        onClose={() => modal.hide("map")}
      >
        <MyMap location={location} />
      </Popup>
      <Popup
        show={modal.modals.includes("protocol")}
        size="md"
        title="RequestProtocol"
        onClose={() => modal.hide("protocol")}
      >
        <AssignProtocol
          id={selectedData?.id}
          onClose={() => modal.hide("protocol")}
          refresh={() => props.onGet(selectedData)}
        />
      </Popup>
      <React.Fragment
        ref={(el) => {
          if (el) {
            el.parentElement.style.padding = "0";
          }
        }}
      >
        <Print
          onClose={() => setPrint(false)}
          print={print}
          item={selectedData}
        />
        {payInfo ? (
          <React.Fragment>
            {loading ? <ComponentLoader /> : null}
            {!loading && (
              <button
                className="btn shadow-none"
                onClick={() => {
                  setPayInfo(null);
                  setTimeout(() => {
                    setLoading(true);
                  }, 300);
                }}
                style={{
                  position: "absolute",
                  right: 10,
                  fontSize: 20,
                  color: "wheat",
                }}
              >
                <i className="feather feather-x" />
              </button>
            )}
            <iframe
              src={`https://api.az/${payInfo}`}
              width="100%"
              height="510"
              allowFullScreen
              onLoad={onLoad}
              style={{ border: "none", borderRadius: 7 }}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {props.object.map((item, i) => (
              <Fragment key={i}>
                {item.protocol_number ||
                item.fullname ||
                item.amount ||
                item.discount ||
                item.car_number ||
                item.total ||
                item.has_decision ||
                item.speed_max ||
                item.speed_real ||
                item.action_date ||
                item.decision_date ||
                item.address ||
                item.law_item ? (
                  <React.Fragment>
                    <div
                      className="custom-row pt-4"
                      style={{ borderBottom: "none" }}
                      ref={props.printRef[i]}
                    >
                      {item.car_number ? (
                        <div className="custom-row__data">
                          <p className="data-title">
                            {translate.get("Number")}:
                          </p>
                          <p className="data-content">{item.car_number}</p>
                        </div>
                      ) : null}
                      {item.protocol_number ? (
                        <div className="custom-row__data">
                          <p className="data-title">
                            {translate.get("Protocol")}:
                          </p>
                          <p className="data-content">
                            {item.protocol_series + item.protocol_number}
                          </p>
                        </div>
                      ) : null}

                      {item.fullname.trim().length ? (
                        <div className="custom-row__data">
                          <p className="data-title">
                            {translate.get("Fined")}:
                          </p>
                          <p className="data-content">{item.fullname}</p>
                        </div>
                      ) : null}

                      {item.amount ? (
                        <div className="custom-row__data">
                          <p className="data-title">{translate.get("Fine")}:</p>
                          <p className="data-content">{`${item.amount} AZN`}</p>
                        </div>
                      ) : null}

                      {item.discount ? (
                        <div className="custom-row__data">
                          <p className="data-title">
                            {translate.get("Discount")}:
                          </p>
                          <p className="data-content">{`${item.discount} AZN`}</p>
                        </div>
                      ) : null}

                      {item.total ? (
                        <div className="custom-row__data">
                          <p className="data-title">
                            {translate.get("FinalAmount")}:
                          </p>
                          <p className="data-content">{`${item.total} AZN`}</p>
                        </div>
                      ) : null}

                      <div className="custom-row__data">
                        <p className="data-title">{translate.get("Status")}</p>
                        <p className="data-content">{item.status.text}</p>
                      </div>

                      {item.speed_max ? (
                        <div className="custom-row__data">
                          <p className="data-title">
                            {translate.get("SpeedLimit")}:
                          </p>
                          <p className="data-content">{`${
                            item.speed_max
                          } ${translate.get("KmHour")}`}</p>
                        </div>
                      ) : null}

                      {item.speed_real ? (
                        <div className="custom-row__data">
                          <p className="data-title">
                            {translate.get("OverSpeed")}:
                          </p>
                          <p className="data-content">{`${
                            item.speed_real
                          } ${translate.get("KmHour")}`}</p>
                        </div>
                      ) : null}

                      {item.action_date ? (
                        <div className="custom-row__data">
                          <p className="data-title">{translate.get("Date")}:</p>
                          <p className="data-content">{item.action_date}</p>
                        </div>
                      ) : null}

                      {item.decision_date ? (
                        <div className="custom-row__data">
                          <p className="data-title">
                            {translate.get("DecisionDate")}:
                          </p>
                          <p className="data-content">{item.decision_date}</p>
                        </div>
                      ) : null}

                      {item.address ? (
                        <div className="custom-row__data">
                          <p className="data-title">
                            {translate.get("PlaceOfRegistration")}:
                          </p>
                          <p className="data-content">{item.address}</p>
                        </div>
                      ) : null}

                      {item.law_item ? (
                        <div className="custom-row__data">
                          <p className="data-title">{translate.get("İXM")}:</p>
                          <p className="data-content">{item.law_item}</p>
                        </div>
                      ) : null}
                    </div>
                    <div className="custom-row pb-4">
                      {item.has_image || item.can_pay ? (
                        <div className="d-flex justify-content-center w-100 flex-wrap">
                          {!!item.location && (
                            <button
                              className="custom-btn_view br px-3 mx-2"
                              onClick={() => {
                                modal.show("map");
                                setLocation([
                                  item.location?.latitude,
                                  item.location?.longitude,
                                ]);
                              }}
                            >
                              <i className="feather feather-map-pin mr-2" />
                              {translate.get("SeeOnMap")}
                            </button>
                          )}
                          {item.has_image ? (
                            <button
                              className="custom-btn_view br px-3 mx-2"
                              onClick={() => {
                                props.setID(item.id);
                                props.onShow();
                              }}
                            >
                              {translate.get("SeePhotoAndVideo")}
                            </button>
                          ) : null}
                          {!!item.assign_protocol && (
                            <button
                              className="custom-btn_pay br px-3 mx-2"
                              onClick={() => {
                                if (item.assign_status?.id === 1) {
                                  toast.fire({
                                    icon: "warning",
                                    title: item.assign_status?.title,
                                  });
                                } else {
                                  modal.show("protocol");
                                  setSelectedData(item);
                                }
                              }}
                            >
                              {translate.get("RequestProtocol")}
                            </button>
                          )}
                          {item.can_pay ? (
                            <button
                              className="custom-btn_pay br px-3 mx-2"
                              onClick={() => {
                                window.open(
                                  `https://api.az/${
                                    item.protocol_series + item.protocol_number
                                  }`
                                );
                              }}
                            >
                              {translate.get("PayOnline")}
                            </button>
                          ) : null}
                          <button
                            onClick={() =>
                              setSelectedData(item) + setPrint(true)
                            }
                            className="custom-btn_pay br px-3 mx-2"
                          >
                            <img
                              src="https://img.icons8.com/ios/20/089b7e/print--v1.png"
                              alt="print"
                              className="print_dark"
                            />
                            <img
                              src="https://img.icons8.com/ios/20/ffffff/print--v1.png"
                              alt="print"
                              className="print_white"
                            />
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </React.Fragment>
                ) : null}
              </Fragment>
            ))}
            <div className="my-3 d-flex justify-content-center export__btn_one">
              <Link
                target="blank"
                to={{
                  pathname: `https://smsradar.az/apixxx/protocols/export?car_number=${
                    globalFunctions._getCarInfo().car_number
                  }&token=${globalState.get("token")}`,
                }}
                className="custom-btn br success-btn mr-lg-2 mr-0"
                download
              >
                {translate.get(
                  `${globalFunctions._getCarInfo().car_number} ${translate.get(
                    "ExportFineExcel"
                  )}`
                )}
              </Link>
              <Link
                to={{ pathname: `/protocol/all/${props.protocolID}` }}
                target="_blank"
                className="custom-btn br success-btn mt-lg-0 mt-2"
              >
                <img
                  src="https://img.icons8.com/ios/20/ffffff/print--v1.png"
                  alt="print"
                  className="print_white mr-2"
                />
                {translate.get(
                  `${globalFunctions._getCarInfo().car_number} ${translate.get(
                    "PrintTheFine"
                  )}`
                )}
              </Link>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    </React.Fragment>
  );
};
