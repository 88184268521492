import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Exam } from "../layouts";

export class ExamContainer extends React.Component {
  render() {
    return (
        <div className='content' >
          <Switch>
            <Route exact path="/exam" component={Exam} />
            <Redirect to="/" />
          </Switch>
        </div>
    );
  }
}
