import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { InsuranceTable, InsuranceInfo } from "../layouts";

export class InsuranceContainer extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/insurance" component={InsuranceTable} />
        <Route exact path="/insurance/info" component={InsuranceInfo} />
        <Redirect to="/" />
      </Switch>
    );
  }
}
