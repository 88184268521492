import React from "react";
import { ComponentHeader } from "../../components";
import { globalState, translate } from "../../lib";

export class InsuranceTable extends React.Component {
  render() {
    return (
      <div className="content">
        <div className="Insurance">
          <ComponentHeader
            title={translate.get("İcbari Sığorta")}
            back={"/"}
            msg={true}
            grid={true}
          />
          <iframe
            src={`https://smsradar.az/isb/`}
            width="100%"
            height="500"
            allowFullScreen
            style={{ border: "none" }}
          />
          {/*<div className="insurance__container">*/}
          {/*  <p className="mb-4">*/}
          {/*    “İCBARİ SIĞORTALAR HAQQINDA” AZƏRBAYCAN RESPUBLİKASININ QANUNU*/}
          {/*    (çıxarış)*/}
          {/*  </p>*/}
          {/*  <p className="mb-4">*/}
          {/*    Avtonəqliyyat vasitəsi sahiblərinin mülki məsuliyyətinin icbari*/}
          {/*    sığortası mexaniki avtonəqliyyat vasitələrinin Azərbaycan*/}
          {/*    Respublikasının ərazisində istifadəsi nəticəsində fiziki şəxslərin*/}
          {/*    sağlamlığına, həmçinin fiziki və hüquqi şəxslərin əmlakına vurduğu*/}
          {/*    zərərlərin əvəzinin ödənilməsi məqsədi ilə tətbiq edilir. Baza*/}
          {/*    sığorta haqqı 50,0 manat məbləğində müəyyən olunur. Avtonəqliyyat*/}
          {/*    vasitəsi sahiblərinin mülki məsuliyyətinin icbari sığortası*/}
          {/*    müqaviləsi bağlanarkən ödənilməli olan bir illik sığorta haqqı*/}
          {/*    məbləğinin hesablanması üçün avtonəqliyyat vasitəsinin növünə görə*/}
          {/*    aşağıdakı əmsallar tətbiq olunur:*/}
          {/*  </p>*/}
          {/*  <p className="mb-4">*/}
          {/*    <b>*/}
          {/*      1. Minik avtomobillərinin və onların bazasında istehsal edilmiş*/}
          {/*      digər avtonəqliyyat vasitələri – mühərriklərinin həcminə görə:*/}
          {/*    </b>*/}
          {/*  </p>*/}
          {/*  <div className="col-md-12 mb-4">*/}
          {/*    <table className="table table-hover table-bordered">*/}
          {/*      <tbody>*/}
          {/*        <tr>*/}
          {/*          <td>*/}
          {/*            -50 sm<sup>3</sup> - 1500 sm<sup>3</sup>*/}
          {/*          </td>*/}
          {/*          <td>əmsal</td>*/}
          {/*          <td>1</td>*/}
          {/*          <td>sığorta haqqı</td>*/}
          {/*          <td>50 manat</td>*/}
          {/*        </tr>*/}
          {/*        <tr>*/}
          {/*          <td>*/}
          {/*            -1501 sm<sup>3</sup> - 2000 sm<sup>3</sup>*/}
          {/*          </td>*/}
          {/*          <td>əmsal</td>*/}
          {/*          <td>1,5</td>*/}
          {/*          <td>sığorta haqqı</td>*/}
          {/*          <td>75 manat</td>*/}
          {/*        </tr>*/}
          {/*        <tr>*/}
          {/*          <td>*/}
          {/*            -2001 sm<sup>3</sup> - 2500 sm<sup>3</sup>*/}
          {/*          </td>*/}
          {/*          <td>əmsal</td>*/}
          {/*          <td>2</td>*/}
          {/*          <td>sığorta haqqı</td>*/}
          {/*          <td>100 manat</td>*/}
          {/*        </tr>*/}
          {/*        <tr>*/}
          {/*          <td>*/}
          {/*            -2501 sm<sup>3</sup> - 3000 sm<sup>3</sup>*/}
          {/*          </td>*/}
          {/*          <td>əmsal</td>*/}
          {/*          <td>2,5</td>*/}
          {/*          <td>sığorta haqqı</td>*/}
          {/*          <td>125 manat</td>*/}
          {/*        </tr>*/}
          {/*        <tr>*/}
          {/*          <td>*/}
          {/*            -3001 sm<sup>3</sup> - 3500 sm<sup>3</sup>*/}
          {/*          </td>*/}
          {/*          <td>əmsal</td>*/}
          {/*          <td>3</td>*/}
          {/*          <td>sığorta haqqı</td>*/}
          {/*          <td>150 manat</td>*/}
          {/*        </tr>*/}
          {/*        <tr>*/}
          {/*          <td>*/}
          {/*            -3501 sm<sup>3</sup> - 4000 sm<sup>3</sup>*/}
          {/*          </td>*/}
          {/*          <td>əmsal</td>*/}
          {/*          <td>3,5</td>*/}
          {/*          <td>sığorta haqqı</td>*/}
          {/*          <td>175 manat</td>*/}
          {/*        </tr>*/}
          {/*        <tr>*/}
          {/*          <td>*/}
          {/*            -4001 sm<sup>3</sup> -4500 sm<sup>3</sup>*/}
          {/*          </td>*/}
          {/*          <td>əmsal</td>*/}
          {/*          <td>4</td>*/}
          {/*          <td>sığorta haqqı</td>*/}
          {/*          <td>200 manat</td>*/}
          {/*        </tr>*/}
          {/*        <tr>*/}
          {/*          <td>*/}
          {/*            -4501 sm<sup>3</sup> – 5000*/}
          {/*          </td>*/}
          {/*          <td>əmsal</td>*/}
          {/*          <td>4,5</td>*/}
          {/*          <td>sığorta haqqı</td>*/}
          {/*          <td>225 manat</td>*/}
          {/*        </tr>*/}
          {/*        <tr>*/}
          {/*          <td>*/}
          {/*            -5001 sm<sup>3</sup> –dən çox*/}
          {/*          </td>*/}
          {/*          <td>əmsal</td>*/}
          {/*          <td>5</td>*/}
          {/*          <td>sığorta haqqı</td>*/}
          {/*          <td>250 manat.</td>*/}
          {/*        </tr>*/}
          {/*      </tbody>*/}
          {/*    </table>*/}
          {/*  </div>*/}
          {/*  <p className="mb-4">*/}
          {/*    <b>*/}
          {/*      2. Avtobuslar, mikroavtobuslar və onların bazasında istehsal*/}
          {/*      edilmiş digər avtonəqliyyat vasitələri –sərnişin yerlərinin*/}
          {/*      sayına görə:*/}
          {/*    </b>*/}
          {/*  </p>*/}
          {/*  <div className="col-md-12 mb-4">*/}
          {/*    <table className="table table-hover table-bordered">*/}
          {/*      <tbody>*/}
          {/*        <tr>*/}
          {/*          <td>-9 – 16</td>*/}
          {/*          <td>əmsal</td>*/}
          {/*          <td>3</td>*/}
          {/*          <td>sığorta haqqı</td>*/}
          {/*          <td>150 manat;</td>*/}
          {/*        </tr>*/}
          {/*        <tr>*/}
          {/*          <td>-16 – dan artıq</td>*/}
          {/*          <td>əmsal</td>*/}
          {/*          <td>4</td>*/}
          {/*          <td>sığorta haqqı</td>*/}
          {/*          <td>200 manat.</td>*/}
          {/*        </tr>*/}
          {/*      </tbody>*/}
          {/*    </table>*/}
          {/*  </div>*/}
          {/*  <p className="mb-4">*/}
          {/*    <b>*/}
          {/*      3. Yük avtomobilləri və onların bazasında istehsal edilmiş digər*/}
          {/*      avtonəqliyyat vasitələri – icazə verilmiş maksimum kütləyə görə:*/}
          {/*    </b>*/}
          {/*  </p>*/}
          {/*  <div className="col-md-12 mb-4">*/}
          {/*    <table className="table table-hover table-bordered">*/}
          {/*      <tbody>*/}
          {/*        <tr>*/}
          {/*          <td>-3500 kq-dan çox olmayan</td>*/}
          {/*          <td>əmsal</td>*/}
          {/*          <td>3</td>*/}
          {/*          <td>sığorta haqqı</td>*/}
          {/*          <td>150 manat;</td>*/}
          {/*        </tr>*/}
          {/*        <tr>*/}
          {/*          <td>-3501 kq – 7000 kq</td>*/}
          {/*          <td>əmsal</td>*/}
          {/*          <td>4</td>*/}
          {/*          <td>sığorta haqqı</td>*/}
          {/*          <td>200 manat;</td>*/}
          {/*        </tr>*/}
          {/*        <tr>*/}
          {/*          <td>-7000 kq-dan yuxarı</td>*/}
          {/*          <td>əmsal</td>*/}
          {/*          <td>5</td>*/}
          {/*          <td>sığorta haqqı</td>*/}
          {/*          <td>250 manat.</td>*/}
          {/*        </tr>*/}
          {/*      </tbody>*/}
          {/*    </table>*/}
          {/*  </div>*/}
          {/*  <p className="mb-4">*/}
          {/*    <b>4. Motosikletlər və motorolerlər</b>*/}
          {/*  </p>*/}
          {/*  <div className="col-md-12 mb-4">*/}
          {/*    <table className="table table-hover table-bordered">*/}
          {/*      <tbody>*/}
          {/*        <tr>*/}
          {/*          <td>§</td>*/}
          {/*          <td>əmsal</td>*/}
          {/*          <td>1</td>*/}
          {/*          <td>sığorta haqqı</td>*/}
          {/*          <td>50 manat.</td>*/}
          {/*        </tr>*/}
          {/*      </tbody>*/}
          {/*    </table>*/}
          {/*  </div>*/}
          {/*  <p>*/}
          {/*    <b>*/}
          {/*      Üçüncü şəxsə dəyə bilən zərərə görə avtonəqliyyat vasitəsi*/}
          {/*      sahiblərinin mülki məsulliyyətinin icbari sığortası üzrə sığorta*/}
          {/*      məbləğləri aşağıdakı kimi müəyyən edilir*/}
          {/*    </b>*/}
          {/*  </p>*/}
          {/*  <p>*/}
          {/*    - fiziki şəxslərin sağlamlığına dəyən zərərə görə sığorta məbləği*/}
          {/*    bir hadisə üzrə 50.000 manatdan çox olmamaq şərti ilə, bir fiziki*/}
          {/*    şəxsin sağlamlığına dəyən zərər üzrə 5000 manat;*/}
          {/*  </p>*/}
          {/*  <p>- üçüncü şəxslərin amlakına dəyən zərər üzrə 5000 manat.</p>*/}
          {/*</div>*/}
        </div>
      </div>
    );
  }
}
