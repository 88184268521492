import React from "react";
import { Modal } from "react-bootstrap";
import { translate } from "../../lib";

export const NotInsuranceModal = ({ NoInsurance, onClose, url = "" }) => {
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={NoInsurance}
      onHide={onClose}
    >
      <Modal.Header>
        <div className="d-flex justify-content-between align-items-center w-100">
          <h5 className="mb-0">{translate.get("Insurance")}</h5>
          <button className="close-btn feather feather-x" onClick={onClose} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <p style={{ color: "#777", fontSize: 18 }} className="text-center mb-0">
          {url}
        </p>
        <div className="w-100 px-2   mt-3">
          <button className="custom-btn btn-block" onClick={onClose}>
            {translate.get("Close")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
