import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Navimax as NavimaxJS } from "../layouts";

export class Navimax extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/navimax" component={NavimaxJS} />
        <Redirect to="/" />
      </Switch>
    );
  }
}
