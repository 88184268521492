import React from "react";
import { ComponentLoader, ComponentHeader, Warn } from "../../components";
import { carsAdd } from "../../actions";
import { translate, toast, globalState } from "../../lib";

export class CarAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      step: 1,

      user: globalState.get("account_data"),

      number_type: "aze",
      car_number: "",
      tech_number: "",

      code: "",
      verify_hash: "",
    };
  }

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    let { car_number, tech_number } = this.state;
    let params = null;
    params = {
      car_number,
      tech_id: tech_number,
    };

    let response = await carsAdd(params);
    if (response) {
      this.setState({ isLoading: false });
      toast.fire({
        icon: response.status === "success" ? "success" : "error",
        title: response.description,
      });
      if (response.status === "success") {
        setTimeout(() => {
          window.location = "/";
        }, 1500);
      }
    }
  };

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  render() {
    let { isLoading, number_type, car_number, tech_number } = this.state;

    return (
      <div className="content" style={{ background: "#fff" }}>
        {isLoading ? (
          <ComponentLoader />
        ) : (
          <div className="CarAdd">
            <ComponentHeader
              title={translate.get("AddCar")}
              searchable={false}
              back={"/cars"}
              msg={true}
              grid={true}
            />
            <div className="car-add__content">
              <form onSubmit={this.onSubmit} className="mb-5">
                <div className="row">
                  <React.Fragment>
                    <div className="col-md-12 mb-5">
                      <div className="car__number-type">
                        <div
                          className={`number-item ${
                            number_type === "aze" ? "selected" : ""
                          }`}
                          onClick={() => this.setState({ number_type: "aze" })}
                        >
                          <span>
                            {translate.get(`AZE ${translate.get("Number")}`)}
                          </span>
                        </div>
                        <div
                          className={`number-item ${
                            number_type === "euro" ? "selected" : ""
                          }`}
                          onClick={() =>
                            this.setState({
                              number_type: "euro",
                              tech_number: "",
                            })
                          }
                        >
                          <span>{translate.get("ExportNumber")}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-4">
                      <label className="custom-different-label">
                        {translate.get("CarrNumber")}
                      </label>
                      <input
                        required
                        type="text"
                        className="custom-different-input"
                        placeholder={"00-XX-000"}
                        value={car_number}
                        onChange={(e) => {
                          this.setState({ car_number: e.target.value });
                        }}
                      />
                    </div>
                    {number_type === "aze" ? (
                      <div className="col-md-12 mb-4">
                        <label className="custom-different-label">
                          {translate.get("TechnicalPassNumber")}
                        </label>
                        <input
                          required
                          type="text"
                          className="custom-different-input"
                          placeholder={"AB000000"}
                          value={tech_number}
                          onChange={(e) => {
                            this.setState({ tech_number: e.target.value });
                          }}
                        />
                      </div>
                    ) : null}
                  </React.Fragment>

                  <div className="col-md-12">
                    <button type="submit" className="custom-btn w-100">
                      {translate.get("AcceptIt")}
                    </button>
                  </div>
                </div>
                <div className="mt-5">
                  <Warn desc={"AddCarDesc"} warn={true} br={"#F3EBCF"} />
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }
}
