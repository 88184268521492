import React from "react";
import { Modal } from "react-bootstrap";
import { ComponentLoader } from "../../components";
import { translate } from "../../lib";

export const Activity = ({ activity, onClose, activityLoading, car }) => {
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={activity}
      onHide={onClose}
    >
      <Modal.Header>
        <div className="d-flex justify-content-between align-items-center w-100">
          <h5 className="mb-0">
            {translate.get(car?.status == 1 ? "DeActive" : "Active2")}
          </h5>
          <button className="close-btn feather feather-x" onClick={onClose} />
        </div>
      </Modal.Header>
      <Modal.Body>
        {activityLoading ? (
          <div className="p-5">
            <ComponentLoader />
          </div>
        ) : (
          <>
            <p
              style={{ color: "#777", fontSize: "18px" }}
              className="text-center"
            >
              {translate
                .get("SmsradarDeaktiveByNumber")
                .replace("{number}", car?.car_number)}
            </p>
            <div className="w-100 px-2   mt-3">
              <button className="custom-btn btn-block" onClick={onClose}>
                {translate.get("Close")}
              </button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
