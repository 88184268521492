import React from "react";
import { Link } from "react-router-dom";
import { translate, user, functions, globalState } from "../../lib";
import { hideModal } from "../../actions";
import { Modal } from "./components";
import { ComponentHeader } from "../../components/Header";
import $ from "jquery";
import { Icon } from "../../components/Icon";

export class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    var permission = globalState.getData()?.permissions;
    this.state = {
      modals: [],
      links: [
        {
          path: "/protocol",
          bColor: "#ED3D67",
          image: "protocol",
          text: "ProtocolCars",
          permissions: true,
        },
        {
          path: "/cars",
          bColor: "#FF9146",
          image: "car",
          text: "MyCars",
          permissions: true,
        },
        {
          path: "/licenses",
          bColor: "#1EBCDB",
          image: "licenses",
          text: "DriverIds",
          permissions: true,
        },
        {
          path: "/protocol/pay",
          bColor: "#7E8EA5",
          image: "pay_pro",
          text: "PayFine",
          permissions: true,
        },
        {
          path: "/faq",
          bColor: "#685EE5",
          image: "faq",
          text: "Questions",
          permissions: true,
        },
        {
          path: "/exam",
          bColor: "#822EE6",
          image: "game",
          text: "OnlineExam",
          permissions: true,
        },
        {
          path: "/law",
          bColor: "#FF9146",
          image: "law",
          text: "Items",
          permissions: true,
        },
        {
          path: "/navimax",
          bColor: "#1F92FD",
          image: "NaviMax",
          text: "NaviGpsTitle",
          ...(permission?.show_new_icons?.allow && {
            badge: (
              <span className="dash__link-badge badge">
                {translate.get("New")}
              </span>
            ),
          }),
          permissions: permission?.navimax_view?.allow,
        },
        {
          path: "/insurance/info",
          bColor: "#ED3D67",
          image: "insurance",
          text: "News",
          permissions: true,
        },
        {
          path: "/accidenthistory",
          bColor: "#FF6400",
          image: "car-accident",
          text: "AccidentHistory",
          ...(permission?.show_new_icons?.allow && {
            badge: (
              <span className="dash__link-badge badge">
                {translate.get("New")}
              </span>
            ),
          }),
          permissions: permission?.accident_history_view?.allow,
        },
        {
          path: "/cards",
          bColor: "#FFB800",
          image: "coins",
          text: "CardList",
          permissions: permission?.cards_view?.allow,
        },
        {
          path: "/balance",
          bColor: "#2A50D7",
          image: "card",
          text: "AddBalance",
          // balance: user.getData().balance,
          permissions: permission?.balance_view?.allow,
        },
      ],

      screenWidth: window.innerWidth,
    };

    $(window).resize(() => {
      this.setState({ screenWidth: $(window).innerWidth() });
    });
  }

  render() {
    let { links, modals, screenWidth } = this.state;

    return (
      <div>
        {screenWidth < 1025 ? (
          <ComponentHeader msg={true} logout={true} operator={true} />
        ) : null}
        <div className="Dashboard">
          {functions.in_array("share", modals) ? (
            <Modal
              onClose={() => {
                hideModal.bind(this)("share");
              }}
            />
          ) : null}
          {links.map(
            (link, i) =>
              link.permissions && (
                <div className="custom-col-md-20 custom-col-4" key={i}>
                  <Link
                    target={link.blank ? "blank" : ""}
                    to={{ pathname: link.path }}
                    className="dash__link-item"
                    onClick={link.action}
                  >
                    <div
                      className="dash__link-icon"
                      style={{
                        backgroundColor: link.bColor,
                      }}
                    >
                      {link.badge && link.badge}
                      <Icon icon={link.image} />
                    </div>
                    <div className="dash__link-text">
                      <p>{translate.get(link.text)}</p>
                    </div>
                    {link.balance ? (
                      <div className="dash__link-balance">
                        <p>{`(${link.balance} AZN)`}</p>
                      </div>
                    ) : null}
                  </Link>
                </div>
              )
          )}
        </div>
      </div>
    );
  }
}
