import { globalState, functions, translate } from ".";
import { config } from "../config";
import { toast } from "./toast";
import cookie from "react-cookies";

function filterParams(params) {
  let obj = {};
  Object.keys(params).map((key) => {
    let value = params[key];
    if (!value) {
      value = "";
    }
    obj[key] = value;

    return obj;
  });
  return obj;
}

export async function get(url, params = {}, ignore = false) {
  let _lang = cookie.load("sms_lang") || "az";

  if (typeof _lang !== "undefined") {
    params.lang = _lang;
  }
  params.app_id = 12;

  let response = await fetch(
    `${globalState.get("apiRoutes")[url]}?${functions.serializeQuery(
      filterParams(params)
    )}`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }
  )
    .then(async (response) => response && (await response.json()))
    .catch((err) => {
      toast.fire({
        icon: "error",
        title: translate.get("ServerProblem"),
        timer: 10000,
      });
    });

  if (response && response.error_code === 1001) {
    throw Error(response.description);
  }
  return response;
}

export const post = async (url, params = {}, ignore = false) => {
  let _lang = cookie.load("sms_lang") || "az";

  if (typeof _lang !== "undefined") {
    params.lang = _lang;
  }
  params.app_id = 12;

  return await fetch(
    globalState.get("apiRoutes")[url] + "?_env=" + config.env,
    {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: params,
    }
  ).then(async (response) => await response.json());
};

export const put = async (url, params = {}, ignore = false) => {
  let _lang = cookie.load("sms_lang") || "az";

  if (_lang) {
    params.append("lang", _lang);
  }
  params.app_id = 12;

  params.append("_env", config.env);

  return await fetch(`${globalState.get("apiRoutes")[url]}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "User-Agent": "Mozilla/5.0",
    },
    credentials: "include",
    mode: "cors",
    cache: "no-cache",
    method: "POST",
    body: params,
  }).then(async (response) => await response.json());
};
