import React from "react";
import { ComponentLoader, ComponentHeader, Warn } from "../../components";
import { translate, toast } from "../../lib";

export class ProtocolPay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      object: props.location.object
        ? JSON.parse(props.location.object.data)
        : null,
      protocol_series: "",
      protocol_serie: "",
      protocol_id: "",
      show_iframe: false,
      loading_iframe: true,
    };
  }

  onSubmit = async (e) => {
    e.preventDefault();

    let { protocol_serie } = this.state;

    if (protocol_serie.trim()) {
      window.open(
        `https://api.az/${
          protocol_serie.slice(0, 2) + protocol_serie.slice(2, 20)
        }`
      );
    } else {
      this.setState({
        isLoading: false,
        loading_iframe: false,
        show_iframe: false,
      });
      toast.fire({
        icon: "error",
        title: translate.get("EnterCorrectData"),
      });
    }
  };

  componentDidMount() {
    if (this.state.object) {
      let { protocol_series, protocol_number } = this.state.object;
      this.setState({
        protocol_serie: protocol_series + protocol_number,
        protocol_id: protocol_number,
      });
    }
    window.scrollTo(0, 0);
  }

  onLoad = () => {
    this.setState({ loading_iframe: false });
  };

  render() {
    let { isLoading, protocol_serie } = this.state;

    return (
      <div className="content" style={{ background: "#fff" }}>
        {isLoading ? (
          <ComponentLoader />
        ) : (
          <div className="Pay">
            <ComponentHeader
              title={translate.get("PayFine")}
              back={"/"}
              msg={true}
              grid={true}
            />
            <div className="protocol-pay__content">
              <form onSubmit={this.onSubmit}>
                <div className="row">
                  <>
                    <div className="col-md-12 mb-4">
                      <label className="custom-different-label">
                        {translate.get("ProtocolSeria")}
                      </label>
                      <input
                        required
                        type="text"
                        maxLength={13}
                        className="custom-different-input"
                        placeholder={translate.get("EQ123456")}
                        value={protocol_serie}
                        onChange={(e) => {
                          this.setState({ protocol_serie: e.target.value });
                        }}
                      />
                    </div>
                    <div className="col-md-12">
                      <button type="submit" className="custom-btn  w-100">
                        {translate.get("Continue")}
                      </button>
                    </div>
                  </>
                </div>
              </form>
              <Warn desc={"PayFineDesc"} warn={true} br={"#F3EBCF"} />
            </div>
          </div>
        )}
      </div>
    );
  }
}
