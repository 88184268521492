import React from "react";
import { Modal } from "react-bootstrap";
import { ComponentLoader } from "../../components";
import { globalState, toast, translate } from "../../lib";
import { carsInsuranceCompanies, carsInsuranceSet } from "../../actions";

export const Insurance = React.memo(({ addModal, onClose, car, refresh }) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [company_id, setCompany_id] = React.useState(car?.car_insurer);

  const [date, setDate] = React.useState({
    expire_year: "",
    expire_month: "",
    expire_day: "",
  });
  const [years, setYears] = React.useState([]);
  const [list, setList] = React.useState({
    months: globalState.get("months"),
    years: [],
  });

  const loadData = async () => {
    setLoading(true);
    let response = await carsInsuranceCompanies();
    if (response) {
      if (response.status === "success") {
        setLoading(false);
        setData(response.data);
      } else {
        setData([]);
      }
    }
  };

  const loadDaysbyMonth = (month = 0, year = 0) => {
    let dayCount = new Date(year, month, 0).getDate();
    let days = [];
    for (let i = 1; i <= dayCount; i++) {
      days.push(i);
    }
    setList({ ...list, days });
  };

  const reLoad = () => {
    let ts = new Date();
    ts.setFullYear(ts.getFullYear());
    let date = ts.getFullYear();
    let years = [];
    for (let i = date; i <= date + 3; i++) {
      years.push(i);
    }
    setYears(years);
    loadDaysbyMonth(date.expire_month, date.expire_year);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let month =
      date.expire_month < 10 ? 0 + date.expire_month : date.expire_month;
    let day = date.expire_day < 10 ? 0 + date.expire_day : date.expire_day;
    let response = await carsInsuranceSet({
      company_id,
      date: `${date.expire_year}-${month}-${day}`,
      id: car?.id,
    });
    if (response) {
      toast.fire({
        icon: response.status === "success" ? "success" : "error",
        title: response.description,
      });
      if (response.status === "success") {
        refresh();
        onClose();
      }
    }
  };

  React.useEffect(() => {
    if (!!addModal) {
      reLoad();
      loadData();
    }
  }, [addModal]);

  let insurance_date = car?.insurance_date?.split("-"); // 0il 1ay 2gun
  React.useEffect(() => {
    setCompany_id(car?.car_insurer || "");
    setDate({
      expire_year:
        insurance_date?.map((x) => x)[0] == "0000"
          ? ""
          : insurance_date?.map((x) => x)[0],
      expire_month:
        insurance_date?.map((x) => x)[1] < 10
          ? insurance_date?.map((x) => x)[1].slice(1, 2) == "0"
            ? ""
            : insurance_date?.map((x) => x)[1].slice(1, 2)
          : insurance_date?.map((x) => x)[1] == "00"
          ? ""
          : insurance_date?.map((x) => x)[1],

      expire_day:
        insurance_date?.map((x) => x)[2] < 10
          ? insurance_date?.map((x) => x)[2].slice(1, 2) == "0"
            ? ""
            : insurance_date?.map((x) => x)[2].slice(1, 2)
          : insurance_date?.map((x) => x)[2] == "00"
          ? ""
          : insurance_date?.map((x) => x)[2],
    });
  }, [addModal]);

  return (
    <div>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={addModal}
        onHide={onClose}
      >
        <Modal.Header>
          <div className="d-flex justify-content-between align-items-center w-100">
            <h5 className="mb-0">Dəyiş</h5>
            <button className="close-btn feather feather-x" onClick={onClose} />
          </div>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="p-5">
              <ComponentLoader />
            </div>
          ) : (
            <form onSubmit={onSubmit}>
              <div className="form-group">
                <label className="custom-different-label">Şirkət</label>
                <div className="select-container">
                  <select
                    required
                    className="custom-different-select"
                    value={company_id}
                    onChange={(e) => setCompany_id(e.target.value)}
                  >
                    <option value="" disabled={company_id !== ""}>
                      {translate.get("Seçin")}
                    </option>
                    {data.map((item, i) => (
                      <option key={i} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group mb-1">
                <label className="custom-different-label">
                  {translate.get("Date")}:
                </label>
                <div className="row">
                  <div className="col-md-4 mb-4">
                    <div className="select-container">
                      <select
                        required
                        className="custom-different-select"
                        value={date.expire_day}
                        onChange={(e) =>
                          setDate({ ...date, expire_day: e.target.value })
                        }
                      >
                        <option value="" disabled>
                          {translate.get("Gün")}
                        </option>
                        {list.days?.map((d, i) => (
                          <option key={i} value={d}>
                            {d < 10 ? `0${d}` : d}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4 mb-4">
                    <div className="select-container">
                      <select
                        required
                        className="custom-different-select"
                        value={date.expire_month}
                        onChange={(e) => {
                          setDate({ ...date, expire_month: e.target.value });
                          loadDaysbyMonth(e.target.value, date.expire_year);
                        }}
                      >
                        <option value="" disabled>
                          {translate.get("Ay")}
                        </option>
                        {list.months?.map((m, i) => (
                          <option key={i} value={i + 1}>
                            {m}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4 mb-4">
                    <div className="select-container">
                      <select
                        required
                        className="custom-different-select"
                        value={date.expire_year}
                        onChange={(e) => {
                          setDate({ ...date, expire_year: e.target.value });
                          loadDaysbyMonth(date.expire_month, e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          {translate.get("İl")}
                        </option>
                        {years?.map((y, i) => (
                          <option key={i} value={y}>
                            {y}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <button className="custom-btn btn-block">Dəyiş</button>
              <hr style={{ marginTop: "1.5rem" }} />
              <p style={{ color: "#777", fontSize: 16 }} className="mb-0">
                <b>Qeyd:</b> icbari sığortanın bitmə vaxtına 1 ay qalmış,
                əməkdaşlarımız tərəfindən sizə zəng olunaraq yeni müqavilə
                təklif ediləcək.
              </p>
            </form>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
});
