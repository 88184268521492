import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Profile } from "../layouts";

export class MyProfile extends React.Component {
  render() {
    return (
      <div className="content">
        <Switch>
          <Route path="/profile" component={Profile} />
          <Redirect to="/" />
        </Switch>
      </div>
    );
  }
}
