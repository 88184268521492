import React from "react";
import { translate, cookie } from "../../../lib";
import classNames from "classnames";
import { dataLangs } from "../../../actions";
import { Loading } from "../../templates";
import { ComponentLoader } from "../../Loader";

export const LangModal = () => {
  // const cookie = useCookie();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  const loadData = async () => {
    setLoading(true);
    let response = await dataLangs();
    if (response) {
      setLoading(false);
      if (response.status === "success") {
        setData(response.data);
      }
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  const default_lang = cookie.get("sms_lang") || "az";
  return (
    <div className="langs">
      {loading && (
        <div className="py-5">
          <ComponentLoader />
        </div>
      )}
      {data.map((row, key) => (
        <div
          className={classNames("lang-item", {
            active: default_lang === row.short_code,
          })}
          key={key}
          onClick={() => {
            window.location.reload();
            cookie.set("sms_lang", row.short_code, 60);
          }}
        >
          <div
            style={{
              width: 40,
              height: 40,
              objectFit: "cover",
              borderRadius: "50%",
            }}
          >
            <img
              style={{
                borderRadius: "50%",
              }}
              width={"100%"}
              height={"100%"}
              src={row.flag}
              alt={row.key}
            />
          </div>
          <div className="lang-detail">
            <div className="caption">{row.original}</div>
            {/*<span className="caption">{translate.get(row.original)}</span>*/}
          </div>
          {default_lang === row.short_code && (
            <i className="feather feather-check text-success fs-22 ml-auto" />
          )}
        </div>
      ))}
    </div>
  );
};
