import cookie from "react-cookies";
import { globalFunctions } from ".";

export const globalState = {
  data: {
    auth: false,
    account_data: false,
    token: cookie.load("_token") || false,
    hash: cookie.load("_hash") || false,
    mode: cookie.load("_mode") === "dark" ? "dark" : "light",
    lang: "en",
    lang_name: "English",
    lang_short_names: {
      en: "ENG",
      ru: "РУС",
      az: "AZE"
    },
    operators: [
      { id: 1, name: "Azercell" },
      { id: 2, name: "Bakcell" },
      { id: 3, name: "Nar Mobile" }
    ],
    prefixes: [
      { text: "10" },
      { text: "50" },
      { text: "51" },
      { text: "55" },
      { text: "70" },
      { text: "77" },
      { text: "99" }
    ],
    months: [
      "01 Yanvar",
      "02 Fevral",
      "03 Mart",
      "04 Aprel",
      "05 May",
      "06 Iyun",
      "07 Iyul",
      "08 Avqust",
      "09 Sentyabr",
      "10 Oktyabr",
      "11 Noyabr",
      "12 Dekabr"
    ],
    tech_prefixes: [
      "AA",
      "AB",
      "AC",
      "AD",
      "MA",
      "MB",
      "MC",
      "MD",
      "ME",
      "MF",
      "MG",
      "MH",
      "MI",
      "MJ",
      "MK",
      "ML",
      "MM",
      "MN",
      "MO",
      "MP",
      "MQ",
      "MR",
      "MS",
      "MT",
      "MU",
      "MV",
      "MW",
      "MX",
      "MY",
      "MZ"
    ],
    lang_data: {},
    lang_version: 1,
    langs: [],
    screenW: window.innerWidth > 0 ? window.innerWidth : window.screen.width
  },

  set(data) {
    /* init functions before updating */
    Object.keys(data).forEach(key => {
      if (globalFunctions.get(`before_${key}`)) {
        if (globalFunctions.get(`before_${key}`).isArray()) {
          globalFunctions.get(`before_${key}`).forEach(callback => {
            callback();
          });
        } else {
          globalFunctions.get(`before_${key}`)();
        }
      }
    });

    this.data = {
      ...this.data,
      ...data
    };

    /* init functions after updating */
    Object.keys(data).forEach(key => {
      if (globalFunctions.get(`after_${key}`)) {
        if (globalFunctions.get(`after_${key}`).isArray()) {
          globalFunctions.get(`after_${key}`).forEach(callback => {
            callback();
          });
        } else {
          globalFunctions.get(`after_${key}`)();
        }
      }
    });
  },

  get(key) {
    return this.data[key] || false;
  },

  getData() {
    return this.data;
  }
};
