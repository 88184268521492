import React from "react";

export const Spinner = React.memo(({ fontSize = 18, color = "#fff" }) => {
  return (
    <svg
      width={fontSize}
      height={fontSize}
      viewBox="0 0 450 450"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="rotation-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M276.782 39.3335C232.242 26.9115 184.721 30.9146 142.888 50.6126C101.054 70.3106 67.6947 104.391 48.896 146.636C30.0973 188.882 27.1116 236.478 40.4835 280.742C53.8554 325.006 82.6937 362.987 121.738 387.759C160.782 412.531 207.431 422.441 253.173 415.682C298.916 408.924 340.705 385.947 370.918 350.943C401.13 315.938 417.752 271.24 417.752 225C417.752 216.095 424.971 208.876 433.876 208.876C442.781 208.876 450 216.095 450 225C450 278.975 430.597 331.152 395.33 372.013C360.063 412.873 311.283 439.694 257.887 447.584C204.491 455.473 150.039 443.904 104.462 414.989C58.8858 386.073 25.2228 341.736 9.6138 290.067C-5.9952 238.398 -2.50996 182.84 19.4338 133.526C41.3775 84.2128 80.3173 44.431 129.15 21.4375C177.983 -1.55603 233.454 -6.22886 285.445 8.27138C322.912 18.7207 358.152 40.5565 386.051 68.543L386.175 43.8928C386.219 34.988 393.474 27.8054 402.379 27.85C411.284 27.8946 418.467 35.1496 418.422 44.0544L418.075 113.298C418.053 117.642 416.28 121.793 413.157 124.811C410.033 127.83 405.824 129.461 401.482 129.334L332.267 127.32C323.366 127.061 316.36 119.635 316.619 110.734C316.878 101.833 324.304 94.8274 333.205 95.0864L367.839 96.0942C342.964 69.5233 310.615 48.7693 276.782 39.3335Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M276.782 39.3335C232.242 26.9115 184.721 30.9146 142.888 50.6126C101.054 70.3106 67.6947 104.391 48.896 146.636C30.0973 188.882 27.1116 236.478 40.4835 280.742C53.8554 325.006 82.6937 362.987 121.738 387.759C160.782 412.531 207.431 422.441 253.173 415.682C298.916 408.924 340.705 385.947 370.918 350.943C401.13 315.938 417.752 271.24 417.752 225C417.752 216.095 424.971 208.876 433.876 208.876C442.781 208.876 450 216.095 450 225C450 278.975 430.597 331.152 395.33 372.013C360.063 412.873 311.283 439.694 257.887 447.584C204.491 455.473 150.039 443.904 104.462 414.989C58.8858 386.073 25.2228 341.736 9.6138 290.067C-5.9952 238.398 -2.50996 182.84 19.4338 133.526C41.3775 84.2128 80.3173 44.431 129.15 21.4375C177.983 -1.55603 233.454 -6.22886 285.445 8.27138C322.912 18.7207 358.152 40.5565 386.051 68.543L386.175 43.8928C386.219 34.988 393.474 27.8054 402.379 27.85C411.284 27.8946 418.467 35.1496 418.422 44.0544L418.075 113.298C418.053 117.642 416.28 121.793 413.157 124.811C410.033 127.83 405.824 129.461 401.482 129.334L332.267 127.32C323.366 127.061 316.36 119.635 316.619 110.734C316.878 101.833 324.304 94.8274 333.205 95.0864L367.839 96.0942C342.964 69.5233 310.615 48.7693 276.782 39.3335Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M276.782 39.3335C232.242 26.9115 184.721 30.9146 142.888 50.6126C101.054 70.3106 67.6947 104.391 48.896 146.636C30.0973 188.882 27.1116 236.478 40.4835 280.742C53.8554 325.006 82.6937 362.987 121.738 387.759C160.782 412.531 207.431 422.441 253.173 415.682C298.916 408.924 340.705 385.947 370.918 350.943C401.13 315.938 417.752 271.24 417.752 225C417.752 216.095 424.971 208.876 433.876 208.876C442.781 208.876 450 216.095 450 225C450 278.975 430.597 331.152 395.33 372.013C360.063 412.873 311.283 439.694 257.887 447.584C204.491 455.473 150.039 443.904 104.462 414.989C58.8858 386.073 25.2228 341.736 9.6138 290.067C-5.9952 238.398 -2.50996 182.84 19.4338 133.526C41.3775 84.2128 80.3173 44.431 129.15 21.4375C177.983 -1.55603 233.454 -6.22886 285.445 8.27138C322.912 18.7207 358.152 40.5565 386.051 68.543L386.175 43.8928C386.219 34.988 393.474 27.8054 402.379 27.85C411.284 27.8946 418.467 35.1496 418.422 44.0544L418.075 113.298C418.053 117.642 416.28 121.793 413.157 124.811C410.033 127.83 405.824 129.461 401.482 129.334L332.267 127.32C323.366 127.061 316.36 119.635 316.619 110.734C316.878 101.833 324.304 94.8274 333.205 95.0864L367.839 96.0942C342.964 69.5233 310.615 48.7693 276.782 39.3335Z"
        fill={color}
      />
    </svg>
  );
});
