import React from "react";
import ReactCodeInput from "react-verification-code-input";

export const Verification = (props) => {
    let {onChange, className} = props;

    return (
        <ReactCodeInput
            type='number'
            autoFocus={true}
            onChange={onChange}
            className={className}
        />
    );
}


export const InputPassword = ({
  value,
  onChange,
  placeholder = "Type something...",
  required = false,
  className = "",
}) => {
    const [type, setType] = React.useState("password");
    return (
        <div className="d-flex _ai-center" >
            <input
                className={`custom-input  ${className} `}
                placeholder={placeholder}
                type={type}
                autoFocus
                required={required}
                value={value}
                onChange={onChange}
            />
            <i
                style={{ marginLeft:-30 }}
                className={`${className} feather feather-${
                    type === "password" ? "eye" : "eye-off"
                }`}
                onClick={() => setType(type === "password" ? "text" : "password")}
            />
        </div>
    );
};
