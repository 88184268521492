import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { QuestinList, QuestionAdd } from "../layouts";

export class QuestionContainer extends React.Component {
  render() {
    return (
        <div className='content' >
          <Switch>
            <Route exact path="/faq" component={QuestinList} />
            <Route exact path="/faq/ask" component={QuestionAdd} />
            <Redirect to="/" />
          </Switch>
        </div>
    );
  }
}
