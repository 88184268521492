import React from "react";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";

export const MyMap = ({ location, active = false }) => {
  const position = location;

  const ComponentResize = () => {
    const map = useMap();

    setTimeout(() => {
      map.invalidateSize();
    }, 0);

    return null;
  };

  React.useEffect(() => {
    if (active) {
      const modalBackdrop = document.querySelector(".modal-backdrop");
      if (modalBackdrop) {
        modalBackdrop.classList.add("active");
      }
    }
  }, [active]);

  return (
    <React.Fragment>
      <div
        ref={(el) => {
          if (el) {
            el.parentElement.style.padding = "0";
          }
        }}
      />
      <MapContainer center={position} zoom={18}>
        <ComponentResize />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
          {/*<Popup>*/}
          {/*  A pretty CSS3 popup. <br /> Easily customizable.*/}
          {/*</Popup>*/}
        </Marker>
      </MapContainer>
    </React.Fragment>
  );
};
