import React from "react";
import {
  Card,
  ComponentHeader,
  ComponentLoader,
  Loading,
  Warn,
} from "../../components";
import { globalState, toast, translate } from "../../lib";
import { paymentsDelete, paymentsList, paymentsUpdate } from "../../actions";
import Swal from "sweetalert2";
import classNames from "classnames";
import { Add } from "./components";

export const MyCards = () => {
  const [loading, setLoading] = React.useState(true);
  const [smallLoading, setSmallLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [addModal, setAddModal] = React.useState(false);

  const loadData = async () => {
    setLoading(true);
    let response = await paymentsList({
      limit: 100,
      skip: "",
    });
    if (response) {
      setLoading(false);
      if (response.status === "success") {
        setData(response.data);
      } else {
        setData([]);
      }
    }
  };

  const onDelete = async (id) => {
    Swal.fire({
      title: translate.get("Verify"),
      text: translate.get("VerifyCardDesc"),
      icon: null,
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonClass: "custom-btn success-btn",
      cancelButtonClass: "custom-btn default-btn",
      confirmButtonText: translate.get("Confirm"),
      cancelButtonText: translate.get("Cancel"),
    }).then(async (res) => {
      if (res && res.value) {
        setLoading(true);
        let response = await paymentsDelete({ id });
        if (response) {
          setLoading(false);
          toast.fire({
            icon: response.status === "success" ? "success" : "error",
            title: translate.get("CardDeletedSuccessfully"),
          });
          if (response.status === "success") {
            loadData();
          }
        }
      }
    });
  };

  const getPaymentMethod = async (id) => {
    setSmallLoading(true);
    let response = await paymentsUpdate({ id });
    if (response.status === "success") {
      setSmallLoading(false);
      setData(
        data.map((x) => {
          if (x.id === id) {
            return { ...x, default: 1 };
          } else {
            return { ...x, default: 0 };
          }
        })
      );
    }
  };

  React.useEffect(() => {
    loadData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Card>
      <Card.Header>
        <ComponentHeader
          title={translate.get("MyCards")}
          back={"/"}
          msg={true}
          grid={true}
          onClick={() =>
            window.open(
              `https://smsradar.az/apixxx/card/add?lang=az&token=${globalState.get(
                "token"
              )}&`
            )
          }
          linkText={"CardAdd"}
        />
      </Card.Header>
      <Card.Content>
        {loading ? (
          <ComponentLoader />
        ) : (
          <React.Fragment>
            {data?.length ? (
              <div className="cards_list mt-2">
                {data.map((item, i) => {
                  return (
                    <div key={i} className="d-flex">
                      <i
                        className={classNames("feather feather-trash-2", {
                          activeFeather: item.default,
                        })}
                        onClick={() => onDelete(item.id)}
                      />
                      <label htmlFor={item.id} className="cards_item">
                        <div className="card-top">
                          <span>{i + 1}</span>
                          <p>{item.type}</p>
                        </div>
                        <div className="card-bottom">
                          <p>{translate.get(item.title)}</p>
                          {item.default ? (
                            <div className="d-flex">
                              <p className="text-muted">
                                ({translate.get("BaseMethod")})
                              </p>
                              {smallLoading && (
                                <div
                                  className="spinner-border text-success"
                                  role="status"
                                >
                                  <span className="sr-only">
                                    {translate.get("Loading")}...
                                  </span>
                                </div>
                              )}
                            </div>
                          ) : null}
                        </div>
                        <div
                          className={classNames("checkbox_", {
                            activeCheck: item.default,
                          })}
                        >
                          <div className="my_radio">
                            <input
                              type="radio"
                              id={item.id}
                              name="a"
                              onChange={() => getPaymentMethod(item.id)}
                              defaultChecked={item.default}
                            />
                          </div>
                        </div>
                      </label>
                    </div>
                  );
                })}
                <div className="w-md-50 px-3">
                  <Warn desc={"CarDesc"} warn={true} br={"#F3EBCF"} />
                </div>
              </div>
            ) : (
              <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                <img
                  src="/assets/images/noCards.svg"
                  alt=""
                  className="no_card"
                />
                <button
                  className="custom-btn mt-4 px-5"
                  onClick={() =>
                    window.open(
                      `https://smsradar.az/apixxx/card/add?lang=az&token=${globalState.get(
                        "token"
                      )}&`
                    )
                  }
                >
                  {translate.get("CardAdd")}
                </button>
                <p className="mt-3" style={{ color: "#16A085" }}>
                  {translate.get("NoActiveCard")}
                </p>
                <div className="w-md-50 px-3">
                  <Warn desc={"CarDesc"} warn={true} br={"#F3EBCF"} />
                </div>
              </div>
            )}
          </React.Fragment>
        )}
        {!loading && (
          <React.Fragment>
            {addModal && (
              <Add
                {...{
                  addModal,
                  onClose: () => setAddModal(false) + loadData(),
                  reload: () => loadData(),
                }}
              />
            )}
          </React.Fragment>
        )}
      </Card.Content>
    </Card>
  );
};
