import React from "react";
import { Modal } from "react-bootstrap";
import { translate } from "../../../lib";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const Print = ({ print, onClose, item }) => {
  const _exportPdf = () => {
    html2canvas(document.querySelector("#capture")).then((canvas) => {
      document.body.appendChild(canvas); // if you want see your screenshot in body.
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`smsradar_${item?.car_number}_protocol.pdf`);
    });
  };

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={print}
      onHide={onClose}
    >
      <Modal.Header>
        <div className="d-flex justify-content-between align-items-center w-100">
          <h5 className="mb-0">{item?.car_number}</h5>
          <button className="close-btn feather feather-x" onClick={onClose} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div id="capture">
          {item?.protocol_number ||
          item?.fullname ||
          item?.amount ||
          item?.discount ||
          item?.car_number ||
          item?.total ||
          item?.has_decision ||
          item?.speed_max ||
          item?.speed_real ||
          item?.action_date ||
          item?.decision_date ||
          item?.address ||
          item?.law_item ? (
            <React.Fragment>
              <div className="custom-row" style={{ borderBottom: "none" }}>
                {item?.car_number ? (
                  <div className="custom-row__data">
                    <p className="data-title">{translate.get("Number")}:</p>
                    <p className="data-content">{item?.car_number}</p>
                  </div>
                ) : null}
                {item?.protocol_number ? (
                  <div className="custom-row__data">
                    <p className="data-title">{translate.get("Protocol")}:</p>
                    <p className="data-content">
                      {item?.protocol_series + item?.protocol_number}
                    </p>
                  </div>
                ) : null}

                {item?.fullname.trim().length ? (
                  <div className="custom-row__data">
                    <p className="data-title">{translate.get("Fined")}:</p>
                    <p className="data-content">{item?.fullname}</p>
                  </div>
                ) : null}

                {item?.amount ? (
                  <div className="custom-row__data">
                    <p className="data-title">{translate.get("Fine")}:</p>
                    <p className="data-content">{`${item?.amount} AZN`}</p>
                  </div>
                ) : null}

                {item?.discount ? (
                  <div className="custom-row__data">
                    <p className="data-title">{translate.get("Discount")}:</p>
                    <p className="data-content">{`${item?.discount} AZN`}</p>
                  </div>
                ) : null}

                {item?.total ? (
                  <div className="custom-row__data">
                    <p className="data-title">
                      {translate.get("Yekun məbləğ:")}
                    </p>
                    <p className="data-content">{`${item?.total} AZN`}</p>
                  </div>
                ) : null}

                <div className="custom-row__data">
                  <p className="data-title">{translate.get("Status")}:</p>
                  <p className="data-content">{item?.status.text}</p>
                </div>

                {item?.speed_max ? (
                  <div className="custom-row__data">
                    <p className="data-title">{translate.get("SpeedLimit")}:</p>
                    <p className="data-content">{`${
                      item?.speed_max
                    } ${translate.get("KmHour")}`}</p>
                  </div>
                ) : null}

                {item?.speed_real ? (
                  <div className="custom-row__data">
                    <p className="data-title">{translate.get("OverSpeed")}:</p>
                    <p className="data-content">{`${
                      item?.speed_real
                    } ${translate.get("KmHour")}`}</p>
                  </div>
                ) : null}

                {item?.action_date ? (
                  <div className="custom-row__data">
                    <p className="data-title">{translate.get("Date")}:</p>
                    <p className="data-content">{item?.action_date}</p>
                  </div>
                ) : null}

                {item?.decision_date ? (
                  <div className="custom-row__data">
                    <p className="data-title">
                      {translate.get("DecisionDate")}:
                    </p>
                    <p className="data-content">{item?.decision_date}</p>
                  </div>
                ) : null}

                {item?.address ? (
                  <div className="custom-row__data">
                    <p className="data-title">
                      {translate.get("PlaceOfRegistration")}:
                    </p>
                    <p className="data-content">{item?.address}</p>
                  </div>
                ) : null}

                {item?.law_item ? (
                  <div className="custom-row__data">
                    <p className="data-title">{translate.get("İXM")}:</p>
                    <p className="data-content">{item?.law_item}</p>
                  </div>
                ) : null}
              </div>
            </React.Fragment>
          ) : null}
        </div>
        <div className="form-row">
          <div className="col-4">
            <Link
              to={{
                pathname: `/protocol/item/${item?.id}/${item?.car_number}`,
              }}
              target="_blank"
              className="custom-btn btn-block"
            >
              {translate.get("PrintIt")}
            </Link>
          </div>
          <div className="col-4">
            <button onClick={_exportPdf} className="custom-btn btn-block">
              {translate.get("PdfDownload")}
            </button>
          </div>
          <div className="col-4">
            <button
              className="custom-btn danger-btn btn-block"
              onClick={onClose}
            >
              {translate.get("Close")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
