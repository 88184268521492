import React from "react";
import { Card, ComponentHeader, ComponentLoader, Loading } from "../components";
import { cookie, translate } from "../lib";
import { useHistory } from "react-router-dom";

export const Navimax = () => {
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();
  const lang = cookie.get("sms_lang") || "az";

  const onLoad = () => {
    setLoading(false);
  };
  return (
    <div className="navimax">
      <Card>
        <Card.Header>
          <ComponentHeader
            title={translate.get("NaviGpsLabel")}
            back={"/"}
            msg={true}
            grid={true}
          />
        </Card.Header>
        <Card.Content>
          {loading && <ComponentLoader />}
          {!loading && (
            <div className="left">
              <img src="/assets/images/navimax.png" alt="" />
            </div>
          )}
          <div className="right">
            <iframe
              src={`https://smsradar.az/apixxx/data/shownavimax?lang=${lang}`}
              width="100%"
              height="500"
              sandbox="allow-same-origin allow-scripts allow-downloads allow-modals allow-popups allow-forms"
              allowFullScreen
              onLoad={onLoad}
              style={{ border: "none", borderRadius: 7 }}
            />
          </div>
        </Card.Content>
      </Card>
    </div>
  );
};
