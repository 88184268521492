import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { LicenseList, LicenseAdd } from "../layouts";

export class LicenseContainer extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/licenses" component={LicenseList} />
        <Route exact path="/licenses/add" component={LicenseAdd} />
        <Redirect to="/" />
      </Switch>
    );
  }
}
