import React from "react";
import { Link } from "react-router-dom";
import { translate } from "../../../lib";
import $ from "jquery";

export class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      screenWidth: window.innerWidth,
      links: [
        {
          show: true,
          image: "instagram",
          path:
            "https://www.instagram.com/sharer/sharer.php?u=Artıq avtomobilin icbari sığortasını,texniki baxış vaxtını,həbsi və bütün cərimələrini  (EQ,BNA və s.) indi öyrən! http://SMSRadar.az/app  Əlaqə: 1114"
        },
        {
          show: "web",
          image: "facebook",
          path:
            "https://www.facebook.com/sharer/sharer.php?u=Artıq avtomobilin icbari sığortasını,texniki baxış vaxtını,həbsi və bütün cərimələrini  (EQ,BNA və s.) indi öyrən! http://SMSRadar.az/app  Əlaqə: 1114"
        },
        {
          show: "web",
          image: "twitter",
          path:
            "https://twitter.com/home?status=Artıq avtomobilin icbari sığortasını,texniki baxış vaxtını,həbsi və bütün cərimələrini  (EQ,BNA və s.) indi öyrən! http://SMSRadar.az/app  Əlaqə: 1114"
        },
        {
          show: true,
          image: "whatsapp",
          path:
            "whatsapp://send?text=Artıq avtomobilin icbari sığortasını,texniki baxış vaxtını,həbsi və bütün cərimələrini  (EQ,BNA və s.) indi öyrən! http://SMSRadar.az/app  Əlaqə: 1114"
        }
      ]
    };

    $(window).resize(() => {
      this.setState({ screenWidth: $(window).innerWidth() });
    });

    $(document).on("click", e => {
      if (
        !$(".custom__modal").is(e.target) &&
        $(".custom__modal").has(e.target).length === 0
      ) {
        this.onClose();
      }
    });
  }

  onClose = () => {
    this.props.onClose();
  };

  render() {
    let { links, screenWidth } = this.state;

    return (
      <div className="custom__modal-container">
        <div className="custom__modal">
          <div className="custom__modal-header">
            <div className="modal__title">{translate.get("Paylaş")}</div>
            <button
              className="close-btn feather feather-x"
              onClick={this.onClose}
            ></button>
          </div>
          <div className="custom__modal-body">
            <div className="modal_social-icons">
              {screenWidth > 1024
                ? links
                    .filter(l => l.show === "web")
                    .map((link, i) => (
                      <Link
                        key={i}
                        className="icon-item"
                        target="blank"
                        to={{ pathname: `${link.path}` }}
                      >
                        <div className="icon-image">
                          <img
                            alt=""
                            src={`/assets/images/${link.image}.svg`}
                          />
                        </div>
                      </Link>
                    ))
                : links.map((link, i) => (
                    <Link
                      key={i}
                      className="icon-item"
                      target="blank"
                      to={{ pathname: `${link.path}` }}
                    >
                      <div className="icon-image">
                        <img alt="" src={`/assets/images/${link.image}.svg`} />
                      </div>
                    </Link>
                  ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
