import React from "react";
import Slider from "react-slick";
import $ from "jquery";
import { ComponentHeader } from "../../components/Header";
import { translate } from "../../lib";

export class Sliders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: window.innerWidth,
    };
    $(window).resize(() => {
      this.setState({ screenWidth: $(window).innerWidth() });
    });
  }

  render() {
    let { screenWidth } = this.state;
    const sliderWeb = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const sliderMob = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <>
        {screenWidth < 1025 && <ComponentHeader slide={true} />}
        <div
          className="slider__side"
          style={{
            backgroundImage:
              screenWidth < 1025 && "url('/assets/images/back_icons.svg')",
            backgroundPosition: screenWidth < 1025 && "center",
            backgroundSize: screenWidth < 1025 && "cover",
            backgroundColor: screenWidth < 1025 && "#6EC8AA",
          }}
        >
          <h4>{translate.get("WhySmsRadar")}</h4>

          {screenWidth > 818 ? (
            <div className="slider_">
              <Slider {...sliderWeb}>
                <img src="/assets/images/slide1.jpg" alt="" />
                <img src="/assets/images/slide2.jpg" alt="" />
                <img src="/assets/images/slide3.jpg" alt="" />
              </Slider>
            </div>
          ) : (
            <div className="slider_">
              <Slider {...sliderMob}>
                <img src="/assets/images/slidermob1.png" alt="" />
                <img src="/assets/images/slidemob2.png" alt="" />
                <img src="/assets/images/slidemob3.png" alt="" />
              </Slider>
            </div>
          )}
        </div>
      </>
    );
  }
}
