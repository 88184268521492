import React from "react";
import { ComponentHeader, Loader } from "../../components";
import { translate, toast, globalState } from "../../lib";
import { accountForgotPassword } from "../../actions";
import $ from "jquery";

export class Forgot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      number: "",
      prefix: "",
      prefixes: globalState.get("prefixes"),

      screenWidth: window.innerWidth,
    };

    $(window).resize(() => {
      this.setState({ screenWidth: $(window).innerWidth() });
    });
  }

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    let { number, prefix } = this.state;
    let params = { msisdn: `994${prefix + number}` };

    let response = await accountForgotPassword(params);
    if (response) {
      this.setState({ isLoading: false });

      toast.fire({
        icon: response.status === "success" ? "success" : "error",
        title: response.description,
      });
      if (response.status === "success") {
        setTimeout(() => {
          window.location = "/";
        }, 3000);
      }
    }
  };

  render() {
    let { isLoading, prefixes, prefix, number, screenWidth } = this.state;

    return (
      <div className="content_min">
        {screenWidth < 1025 && <ComponentHeader auth={true} />}
        {isLoading ? (
          <Loader />
        ) : (
          <div className="Forgot">
            <div className="content_side">
              <h1 className="content-title mb-5">
                {translate.get("Şifrəni unutdum")}
              </h1>
              <form onSubmit={this.onSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <label className="custom-label">
                      {translate.get("Number")}
                    </label>
                  </div>
                  <div className="col-4 mb-4">
                    <select
                      required
                      className="custom-select"
                      value={prefix}
                      onChange={(e) => {
                        this.setState({ prefix: e.target.value });
                      }}
                    >
                      <option value="" disabled={prefix !== ""}>
                        {translate.get("Select")}
                      </option>
                      {prefixes.map((pre, i) => (
                        <option value={pre.text} key={i}>
                          {`0${pre.text}`}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-8 mb-4">
                    <input
                      autoFocus
                      required
                      maxLength="7"
                      type="text"
                      className="custom-input"
                      placeholder={translate.get("Number")}
                      value={number}
                      onChange={(e) => {
                        this.setState({ number: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col-md-12 d-flex justify-content-center">
                    <button type="submit" className="light-btn btn br w-100">
                      {translate.get("Send")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }
}
