import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  ProtocolPay,
  PrintContent,
  PrintContentAll,
} from '../layouts';
import ProtocolList from '../layouts/Protocol/List';

export class ProtocolContainer extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/protocol" component={ProtocolList} />
        <Route exact path="/protocol/pay" component={ProtocolPay} />
        <Route exact path="/protocol/item/:id/:number?" component={PrintContent} />
        <Route exact path="/protocol/all/:id?" component={PrintContentAll} />
        <Redirect to="/" />
      </Switch>
    );
  }
}
