import React, { useEffect } from "react";
import { Loader } from "../../components/Loader";
import { translate, toast, globalState, cookie } from "../../lib";
import { ComponentHeader } from "../../components/Header";
import {
  accountForgotPassword,
  accountRegister_FS,
  accountRegister_LS,
  accountSignIn,
  authCheck,
} from "../../actions";
import { InputPassword, Verification } from "../../components/Template";
import { useModal } from "../../components/hooks";
import { in_array } from "../../lib/functions";
import { Modal } from "../../components/Modal";

export const Register = () => {
  const initialState = {
    isLoading: false,
    isAccept: true,
    step: 1,

    operators: globalState.get("operators"),
    operator: "",

    modals: [],
    infoType: "",

    data: {},

    prefixes: globalState.get("prefixes"),
    prefix: "",

    number: "",
    // car_number: "",
    // tech_number: "",

    code: "",
    verify_hash: "",

    password: "",
    remember: false,
  };

  //  actions
  const [state, setState] = React.useReducer((prevState, newState) => {
    return { ...prevState, ...newState };
  }, initialState);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setState({ ...state, isLoading: true });

    let {
      isAccept,
      step,
      operator,
      remember,
      prefix,
      number,
      // car_number,
      // tech_number,
      code,
      verify_hash,
    } = state;

    let params = null;

    if (isAccept && step === 1) {
      params = { operator, msisdn: `994${prefix + number}` };
      let step1 = await authCheck(params);
      if (step1) {
        switch (step1.data.registered) {
          case 0:
            let params = { operator, msisdn: `994${prefix + number}` };
            let register_fr = await accountRegister_FS(params);
            if (register_fr.status === "success") {
              setState({
                step: 3,
                isLoading: false,
                verify_hash: register_fr.verify_hash,
              });
            } else {
              setState({ isLoading: false });
              toast.fire({
                icon: "error",
                title: translate.get("numberError"),
                showCancelButton: false,
              });
            }
            break;
          case 1:
            setState({ step: 2, isLoading: false });
            break;
          default:
        }
      }
    } else if (step === 2) {
      params = {
        msisdn: `994${prefix + number}`,
        password,
        withCredentials: true,
      };
      let step2 = await accountSignIn(params);
      if (step2) {
        setState({ isLoading: true });
        if (step2.status === "success") {
          setTimeout(() => {
            window.location = "/";
          }, 1000);
        } else {
          setState({ isLoading: false });
          toast.fire({
            icon: "error",
            title: translate.get(step2.description),
            showCancelButton: false,
            cancelButtonText: "Ok!",
          });
        }
      }
    } else if (step === 3) {
      params = { code, verify_hash };
      let step3 = await accountRegister_LS(params);
      if (step3) {
        setState({ isLoading: true });
        if (step3.status === "success") {
          cookie.set("_token", step3.token, remember ? 30 : 1);
          cookie.set("_hash", step3.hash, remember ? 30 : 1);
          setTimeout(() => {
            window.location = "/";
          }, 2000);
          toast.fire({
            icon: "success",
            title: translate.get("Uğurla qeydiyatdan keçdiniz"),
            showCancelButton: false,
            cancelButtonText: "Ok!",
          });
        } else {
          setState({ isLoading: false });
          toast.fire({
            icon: "error",
            title: translate.get(step3.description),
            showCancelButton: false,
            cancelButtonText: "Ok!",
          });
        }
      }
    } else {
      setState({ isLoading: false });
      toast.fire({
        icon: "error",
        title: translate.get("PleaseAcceptRules"),
        showCancelButton: false,
      });
    }
  };

  const modal = useModal();
  const [openModal, setOpenModal] = React.useState(false);

  React.useEffect(() => {
    setOpenModal({
      showInfo: () => modal.show("info"),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let {
    isLoading,
    step,
    operator,
    operators,
    prefixes,
    prefix,
    number,
    password,
  } = state;

  const getAccount = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className="col-md-12 mb-2">
              <label className="custom-label">
                {translate.get("Operator")}
              </label>
              <div className="select-container color-g">
                <select
                  required
                  autoFocus
                  className="custom-select"
                  value={operator}
                  onChange={(e) => {
                    setState({
                      operator: parseInt(e.target.value),
                    });
                  }}
                >
                  <option value="" disabled={operator !== ""}>
                    {translate.get("Select")}
                  </option>
                  {operators.map((opr, i) => (
                    <option key={i} value={opr.id}>
                      {opr.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <label className="custom-label">
                {translate.get("YourMobileNumber")}
              </label>
            </div>
            <div className="col-md-4 mb-2">
              <div className="select-container color-g">
                <select
                  required
                  autoFocus
                  className="custom-select"
                  value={prefix}
                  onChange={(e) => {
                    setState({ prefix: e.target.value });
                  }}
                >
                  <option value="" disabled={prefix !== ""}>
                    {translate.get("Select")}
                  </option>
                  {prefixes.map((pre, i) => (
                    <option value={pre.text} key={i}>
                      {`0${pre.text}`}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-8 mb-2">
              <input
                required
                maxLength="7"
                type="text"
                autoFocus
                className="custom-input"
                placeholder={translate.get("Number")}
                value={number}
                onChange={(e) => {
                  setState({ number: e.target.value });
                }}
              />
            </div>
          </>
        );
        // eslint-disable-next-line no-unreachable
        break;
      case 2:
        return (
          <div className="col-md-12 mb-2">
            <label className="custom-label">{translate.get("Password")}</label>
            <InputPassword
              autoFocus
              placeholder={"********"}
              required={true}
              value={password}
              onChange={(e) => setState({ password: e.target.value })}
              className="text-white"
            />
          </div>
        );
        // eslint-disable-next-line no-unreachable
        break;
      case 3:
        return (
          <div className="col-md-12 mb-2">
            <label className="custom-label">
              {translate.get("VerificationCode")}
            </label>
            <Verification
              onChange={(e) => {
                setState({ code: e });
              }}
              className={"register-verification"}
            />
          </div>
        );
        // eslint-disable-next-line no-unreachable
        break;
      default:
    }
  };

  const forgotPass = async () => {
    let response = await accountForgotPassword({
      msisdn: `994${prefix + number}`,
    });
    if (response.status === "success") {
      toast.fire({
        icon: "success",
        title: translate.get("SMSsentToYouNumber"),
        showCancelButton: false,
      });
    } else {
      toast.fire({
        icon: "error",
        title: translate.get("ThisNumberNotRegisterContact114"),
        showCancelButton: false,
      });
    }
  };

  const againSendVC = async () => {
    let params = { operator, msisdn: `994${prefix + number}` };
    let response = await accountRegister_FS(params);
    if (response.status === "success") {
      setState({ step: 3, isLoading: false });
      toast.fire({
        icon: "success",
        title: translate.get("VerificationCodeSent"),
        showCancelButton: false,
      });
    } else {
      toast.fire({
        icon: "error",
        title: translate.get("ThisNumberNotRegisterContact114"),
        showCancelButton: false,
      });
    }
  };

  return (
    <div className="content_min">
      <div className="header_content">
        <ComponentHeader auth={true} />
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <div className="Register">
          {in_array("info", modal.modals) && (
            <Modal onHide={() => modal.hide("info")} />
          )}
          <div className="content_side">
            <div className="content-h">
              {state.step !== 1 ? (
                <i
                  className="feather feather-chevron-left text-white"
                  onClick={() => setState({ step: 1 })}
                  style={{ fontSize: 30, cursor: "pointer" }}
                />
              ) : (
                <div />
              )}
              <h1 className="content-title mb-0">
                {translate.get("LoginToAccount")}
              </h1>
              <div />
            </div>

            <form onSubmit={onSubmit}>
              <div className="row">
                {getAccount()}
                <div className="col-md-12">
                  <div>
                    <button
                      type="submit"
                      className="btn light-btn br w-100 my-3"
                    >
                      {translate.get("Continue")}
                    </button>
                    <div className="col-md-12 mb-2 d-flex justify-content-center">
                      {step === 1 && (
                        <div className="col-md-12 mb-4 text-center mt-2">
                          <div className="custom-checkbox">
                            <label>
                              <input
                                type="checkbox"
                                checked={state.isAccept}
                                onChange={() => {
                                  setState({
                                    ...state,
                                    isAccept: !state.isAccept,
                                  });
                                }}
                              />
                              <span className="checkmark" />
                            </label>
                            <span className="title">
                              <b
                                onClick={(e) =>
                                  openModal.showInfo() + e.stopPropagation()
                                }
                              >
                                {" "}
                                {translate.get("AcceptRules1")}
                              </b>{" "}
                              {translate.get("AcceptRules2")}
                            </span>
                          </div>
                        </div>
                      )}
                      {step === 2 && (
                        <p
                          className="custom-link mt-3"
                          onClick={() => forgotPass()}
                          style={{ cursor: "pointer" }}
                        >
                          {translate.get("ForgotStep1")}
                        </p>
                      )}

                      {step === 3 && (
                        <div className="d-flex justify-content-center flex-column text-center">
                          <p className=" mt-3" style={{ color: "#9ED6CB" }}>
                            {translate.get("SmsSendProblem")}
                          </p>
                          <p
                            className="custom-link"
                            onClick={() => againSendVC()}
                            style={{ cursor: "pointer" }}
                          >
                            {translate.get("SendAgain")}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
