import React from 'react'

export const Card = React.memo(({children, className})=> {
  return (
    <div className={`card ${className}`} >
      {children}
    </div>
  )
})

const Header = ({children}) => {
  return (
    <div className='card-header' >
      {children}
    </div>
  )
}

const Content = ({children}) => {
  return (
    <div className='card-content' >
      {children}
    </div>
  )
}

Card.Header = Header
Card.Content = Content