import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Message } from "../layouts";

export class MessageContainer extends React.Component {
  render() {
    return (
        <div className='content' >
          <Switch>
            <Route exact path="/messages" component={Message} />
            <Redirect to="/" />
          </Switch>
        </div>
    );
  }
}
