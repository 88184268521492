import { api } from "../lib";

export async function paymentsList(params) {
    return await api.get("paymentsList", params);
}

export async function paymentsAdd(params) {
    return await api.get("paymentsAdd", params);
}

export async function paymentsDelete(params) {
    return await api.get("paymentsDelete", params);
}

export async function paymentsUpdate(params) {
    return await api.get("paymentsUpdate", params);
}