import React from "react";
import { ComponentLoader, ComponentHeader } from "../../components";
import { questionAsk } from "../../actions";
import { translate, toast } from "../../lib";

export class QuestionAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      ask: "",
    };
  }

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    let { ask } = this.state;

    let response = await questionAsk({ ask });

    if (response) {
      this.setState({ isLoading: false });
      toast.fire({
        icon: response.status === "success" ? "success" : "error",
        title: response.description,
      });
      if (response.status === "success") {
        this.setState({ ask: "" });
      }
    }
  };

  render() {
    let { isLoading, ask } = this.state;

    return (
      <>
        {isLoading ? (
          <ComponentLoader />
        ) : (
          <div className="QuestionAdd">
            <ComponentHeader
              title={"TypeQuestion"}
              back={"/faq"}
              msg={true}
              grid={true}
            />
            <div className="question__add-container">
              <form onSubmit={this.onSubmit}>
                <div className="row">
                  <div className="col-md-12 mb-4 text-center">
                    <label
                      className="custom-label mb-3"
                      style={{ color: "#16A085", fontWeight: "500" }}
                    >
                      {translate.get("TypeYoursQuestion")}
                    </label>
                    <textarea
                      required
                      className="custom-textarea_simple"
                      rows="10"
                      value={ask}
                      onChange={(e) => {
                        this.setState({ ask: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col-md-12 d-flex justify-content-center">
                    <button type="submit" className="custom-btn">
                      {translate.get("Send")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </>
    );
  }
}
