import React from "react";
import { Card, ComponentHeader, Icons } from "../../components";
import { globalState, toast, translate, user } from "../../lib";
import { authCheck } from "../../actions";

export const Pay = () => {
  const prefixes = globalState.get("prefixes");
  const [state, setState] = React.useReducer(
    (prevState, newState) => ({ ...prevState, ...newState }),
    {
      prefix: "",
      number: "",
      price: "",
      step: user.isAuth() ? 2 : 1,
      loading: false,
      addModal: false,
    }
  );

  let prefix = state.prefix || user.getData()?.msisdn?.slice(3, 5);
  let number = state.number || user.getData()?.msisdn?.slice(5, 12);
  let price = state.price;

  const onSubmit = async (e) => {
    e.preventDefault();
    switch (state.step) {
      case 1:
        setState({ loading: true });
        let response = await authCheck({
          operator: 1,
          msisdn: `994${prefix + number}`,
        });
        if (response) {
          setState({ loading: false });
          if (response.data?.registered) {
            setState({ step: 2 });
          } else {
            toast.fire({
              icon: "error",
              title: translate.get("ThisNumberNotRegister"),
            });
          }
        }
        break;
    }
  };

  const renderContent = () => {
    switch (state.step) {
      case 1:
        return (
          <div className="form-row">
            <div className="form-group col-md-4 mt-md-5 mt-1">
              <label className="custom-different-label">
                {translate.get("Prefix")}
              </label>
              <div className="select-container">
                <select
                  required
                  className="custom-different-select"
                  value={prefix}
                  onChange={(e) => setState({ prefix: e.target.value })}
                >
                  <option value="">{translate.get("Select")}</option>
                  {prefixes.map((pre, i) => (
                    <option value={pre.text} key={i}>
                      {`0${pre.text}`}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group col-md-8 mt-md-5 mt-1">
              <label className="custom-different-label">
                {translate.get("Number")}
              </label>
              <input
                maxLength="7"
                type="tel"
                value={number}
                className="custom-different-input"
                placeholder={translate.get("0000000")}
                onChange={(e) => setState({ number: e.target.value })}
              />
            </div>
            <div className="form-group col-12">
              <button className="custom-btn btn-block mt-2">
                {state.loading ? <Icons /> : translate.get("Continue")}
              </button>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            {!user.isAuth() && (
              <div className="d-flex align-items-center">
                <button
                  className="custom-btn d-flex align-items-center justify-content-center"
                  style={{ padding: "2px 0" }}
                  type="button"
                  onClick={() => setState({ step: 1 })}
                >
                  <i
                    className="feather feather-chevron-left ml-1"
                    style={{ fontSize: "1.4rem", height: "23px" }}
                  />
                </button>
              </div>
            )}
            <div className="form-row mt-3">
              <div className="form-group col-md-4">
                <label className="custom-different-label">
                  {translate.get("Prefix")}
                </label>
                <div className="select-container">
                  <select
                    required
                    className="custom-different-select"
                    value={prefix}
                    disabled={true}
                  >
                    <option value="">{translate.get("Seçin")}</option>
                    {prefixes.map((pre, i) => (
                      <option value={pre.text} key={i}>
                        {`0${pre.text}`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group col-md-8">
                <label className="custom-different-label">
                  {translate.get("Number")}
                </label>
                <input
                  required
                  maxLength="7"
                  type="text"
                  disabled={true}
                  value={number}
                  className="custom-different-input"
                  placeholder={translate.get("0000000")}
                  onChange={(e) => setState({ number: e.target.value })}
                />
              </div>
              <div className="form-group col">
                <label className="custom-different-label">
                  {translate.get("PayPrice")}
                </label>
                <input
                  type="tel"
                  maxLength="3"
                  value={price}
                  className="custom-different-input"
                  placeholder={translate.get("100")}
                  onChange={(e) => setState({ price: e.target.value })}
                />
              </div>
              <div className="form-group col-12">
                {price === "" ? (
                  <button className="custom-btn btn-block mt-2" disabled={true}>
                    {translate.get("Continue")}
                  </button>
                ) : (
                  <button
                    className="custom-btn btn-block mt-2"
                    onClick={() =>
                      window.open(
                        `https://smsradar.az/apixxx/pay/balance?amount=${price}&phone=994${prefix}${number}`
                      )
                    }
                  >
                    {translate.get("Continue")}
                  </button>
                )}
              </div>
            </div>
          </div>
        );
    }
  };
  return (
    <Card className="pay_content">
      <Card.Header>
        <ComponentHeader
          title={translate.get("AddBalance")}
          back={user.isAuth() ? "/balance" : "/"}
          grid={true}
        />
      </Card.Header>
      <Card.Content>
        <form onSubmit={onSubmit}>{renderContent()}</form>
      </Card.Content>
      {/*{state.addModal && (*/}
      {/*  <PayModal*/}
      {/*    {...{*/}
      {/*      addModal: state.addModal,*/}
      {/*      onClose: () => setState({ addModal: false }),*/}
      {/*      reload: () => {},*/}
      {/*      src: `https://smsradar.az/apixxx/pay/balance?token=I10dh0X9aU4J5C0tcQay3U1Y6lbl7ld8227J03aufl7p05bH8_d3bb0e671695aac064db8f06d0bb332c71f8001b&amount=${price}&phone=994${prefix}${number}`,*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}
    </Card>
  );
};
