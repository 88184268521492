import React from "react";
import { translate } from "../../lib";

export class Warn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      desc: this.props.desc,
      br: this.props.br,
      warn: this.props.warn,
      info: this.props.info,
      err: this.props.err,
    };
  }

  render() {
    let { desc, br, warn, info, err } = this.state;

    return (
      <div className="warn" style={{ border: `2px solid ${br}` }}>
        <div className="icon">
          {warn && <img src="/assets/images/warn-warning.svg" alt="" />}

          {info && <img src="/assets/images/warn-info.svg" alt="" />}

          {err && <img src="/assets/images/error.svg" alt="" />}
        </div>

        <p>{translate.get(desc)}</p>
      </div>
    );
  }
}
