import { functions } from "../lib";

export function showModal(modal) {
  let modals = this.state.modals;
  if (!functions.in_array(modal, modals)) {
    modals.push(modal);
    this.setState({
      modals
    });
  }
}

export function hideModal(modal) {
  let modals = this.state.modals;
  if (functions.in_array(modal, modals)) {
    modals = modals.filter(key => key !== modal);
    this.setState({
      modals
    });
  }
}
