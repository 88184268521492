import { globalState } from ".";
export const translate = {
  getData: function () {
    return globalState.get("lang_data") || false;
  },
  get: function (key) {
    return this.getData() && typeof this.getData()[key] === "string"
      ? this.getData()[key]
      : key;
  },
};
