import React from "react";
import { toast, translate } from "../../../lib";
import { Spinner, Warn } from "../../../components";
import { carsDeactivate1, carsDeactivate2 } from "../../../actions";

export const Deaktive = ({ object, _hash, onClose, onRefresh = () => {} }) => {
  const [hash, setHash] = React.useState(_hash);
  const [loading, setLoading] = React.useState(false);
  const [second, setSecond] = React.useState(120);
  const [code, setCode] = React.useState("");

  const sendCodeAgain = async () => {
    setSecond(120);
    let response = await carsDeactivate1({ data: { id: object.id } });
    if (response) {
      setLoading(false);
      if (response.status === "success") {
        setHash(response.data.verify_hash);
      }
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let response = await carsDeactivate2({ data: { verify_hash: hash, code } });
    if (response) {
      setLoading(false);
      toast.fire({
        toast: true,
        position: "bottom-left",
        icon: response.status,
        title: response.data.description,
      });
      if (response.status === "success") {
        onClose();
        onRefresh();
      }
    }
  };

  React.useEffect(() => {
    if (second > 0) {
      const timer = setInterval(() => {
        setSecond((prevSecond) => prevSecond - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [second]);

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="form-group mb-1">
          <label className="custom-different-label">
            {translate.get("VerificationCode")}
          </label>
          <input
            type="text"
            className="custom-different-input"
            placeholder={translate.get("Enter")}
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <TimeFormatter second={second} />
          <button
            className="btn fs-14 shadow-none pr-0"
            style={{ color: "#8391A1" }}
            type="button"
            onClick={sendCodeAgain}
            disabled={second !== 0}
          >
            {translate.get("VerifyCodeAgain")}
          </button>
        </div>
        <button className="custom-btn btn-block mt-2" disabled={loading}>
          {loading ? <Spinner /> : translate.get("Continue")}
        </button>
        <div className="mt-3">
          <Warn desc={"DeaktiveCarDesc"} br={"#F3EBCF"} warn={true} />
        </div>
      </form>
    </div>
  );
};

const TimeFormatter = ({ second }) => {
  const minutes = Math.floor(second / 60);
  const remainingSeconds = second % 60;
  const formattedTime = `${minutes}:${String(remainingSeconds).padStart(
    2,
    "0"
  )}`;

  return (
    <p className="mb-0 fs-14" style={{ color: "#7B7B7B" }}>
      {formattedTime}
    </p>
  );
};
