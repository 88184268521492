import {api} from '../lib';

export async function messageList(params) {
  return await api.get('messageList', params);
}

export async function messageDelete(params) {
  return await api.get('messageDelete', params);
}

export async function messageMultiDelete(params) {
  return await api.get('messageMultiDelete', params);
}
