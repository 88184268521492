import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {Register, Sliders} from '../layouts';
import {PayContainer} from './Pay';

export class AccountContainer extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Sliders}/>
        <Route exact path="/register" component={Register}/>
        <Route path="/pay" component={PayContainer}/>
        <Redirect to="/"/>
      </Switch>
    );
  }
}
