import React from "react";
import {
  ComponentLoader,
  ComponentHeader,
  ScrollLoader,
} from "../../components";
import { questionList, questionReply, questionDelete } from "../../actions";
import { translate, toast } from "../../lib";
import Swal from "sweetalert2";
import $ from "jquery";

export class QuestinList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isScrollLoading: false,
      limit: 50,
      offset: 0,
      count: 0,
      data: [],

      keyword: "",
      user_id: "",

      messageText: "",
      messageID: null,
    };

    $(window).scroll(async () => {
      let scrollableHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      let percent = (
        (document.documentElement.scrollTop / scrollableHeight) *
        100
      ).toFixed();

      if (
        !this.state.isScrollLoading &&
        percent >= 100 &&
        this.state.offset + this.state.limit <= this.state.count
      ) {
        let skip = this.state.offset;
        await this.setState({ offset: (skip += 50) });

        this.setState({ isScrollLoading: true });
        let { limit, offset, keyword, user_id } = this.state;
        let params = { limit, offset, keyword, user_id };
        let response = await questionList(params);
        if (response) {
          this.setState({ isScrollLoading: false });
          if (response.status === "success") {
            let data = this.state.data;
            this.setState({ data: [...data, ...response.data] });
          } else {
            toast.fire({
              icon: "error",
              title: response.description,
            });
          }
        }
      }
    });
  }

  reLoad = async () => {
    this.setState({ isLoading: true });
    let { limit, offset, keyword, user_id } = this.state;
    let params = { limit, offset, keyword, user_id };
    let response = await questionList(params);

    if (response) {
      this.setState({ isLoading: false });
      if (response.status === "success") {
        this.setState({ data: response.data, count: response.count });
      } else {
        this.setState({ data: [], count: 0 });
        toast.fire({
          icon: "error",
          title: response.description,
        });
      }
    }
  };

  filterByUser = async (id) => {
    await this.setState({ user_id: id, offset: 0 });
    document.documentElement.scrollTop = 0;

    this.reLoad();
  };

  onSearch = async (e) => {
    await this.setState({ keyword: e.target.value, offset: 0 });
    document.documentElement.scrollTop = 0;

    if (this.state.keyword.trim().length >= 3) {
      this.reLoad();
    } else if (this.state.keyword.trim().length === 0) {
      await this.setState({ keyword: "" });

      this.reLoad();
    }
  };

  onFocus = (id, e) => {
    this.setState({
      messageText: e.target.value,
      messageID: id,
    });
  };

  onTextChange = (id, e) => {
    this.setState({
      messageText: e.target.value,
      messageID: id,
    });
  };

  onReply = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    let { messageID, messageText } = this.state;
    let params = { id: messageID, reply: messageText };

    let response = await questionReply(params);

    if (response) {
      await this.setState({ isLoading: false, offset: 0 });
      document.documentElement.scrollTop = 0;

      toast.fire({
        icon: response.status === "success" ? "success" : "error",
        title: response.description,
      });

      if (response.status === "success") {
        await this.setState({ messageID: null, messageText: "" });
        this.reLoad();
      }
    }
  };

  onDelete = async (id) => {
    Swal.fire({
      title: translate.get("Verify"),
      text: translate.get("VerifyQuestionDesc"),
      icon: null,
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonClass: "custom-btn success-btn",
      cancelButtonClass: "custom-btn default-btn",
      confirmButtonText: translate.get("Confirm"),
      cancelButtonText: translate.get("Cancel"),
    }).then(async (res) => {
      if (res && res.value) {
        this.setState({
          isLoading: true,
          isScrollLoading: false,
        });
        let response = await questionDelete({ id });
        if (response) {
          this.setState({
            isLoading: false,
          });
          toast.fire({
            icon: response.status === "success" ? "success" : "error",
            title: response.description,
          });
          if (response.status === "success") {
            await this.setState({ messageID: null, messageText: "" });
            this.reLoad();
          }
        }
      }
    });
  };

  async componentDidMount() {
    this.reLoad();
  }

  render() {
    let { isLoading, isScrollLoading, data, keyword, messageID, messageText } =
      this.state;
    return (
      <div className="Question">
        <ComponentHeader
          searchable={true}
          onSearch={this.onSearch}
          searchText={keyword}
          link={"/faq/ask"}
          placeholder={"Search"}
          linkText={"TypeQuestion"}
          title={translate.get("Questions")}
          back={"/"}
          msg={true}
          grid={true}
        />
        <div className="question__container">
          {isLoading ? (
            <ComponentLoader />
          ) : (
            <>
              {data.map((row, i) => (
                <div className="question__item" key={i}>
                  <div className="question__item-header">
                    {/* <div className="question__item-image">
                      <img src="/assets/images/aze_flag.svg" alt="" />
                    </div> */}
                    <div className="question__item-info">
                      <p
                        style={{ cursor: row.can_reply ? "pointer" : "auto" }}
                        onClick={() => {
                          if (row.can_reply) {
                            this.filterByUser(row.user_id);
                          }
                        }}
                        className="item__sender"
                      >{`${i + 1}) ${row.car_number}`}</p>
                      <p className="item__date">{row.date}</p>
                    </div>
                    {row.can_delete ? (
                      <div className="question__item-actions">
                        <button
                          className="item__delete feather feather-trash"
                          onClick={() => this.onDelete(row.id)}
                        ></button>
                      </div>
                    ) : null}
                  </div>
                  <div className="question__item-content">
                    <div className="question__item-question">
                      <p>
                        <b>{translate.get("Question")}</b>
                        <p>{row.question}</p>
                      </p>
                    </div>
                    <div className="question__item-reply">
                      {row.can_reply ? (
                        <form onSubmit={this.onReply}>
                          <textarea
                            className="custom-textarea_simple"
                            style={{ backgroundColor: "#C7ECDF" }}
                            value={
                              messageID === row.id ? messageText : row.reply
                            }
                            onFocus={(e) => {
                              this.onFocus(row.id, e);
                            }}
                            onChange={(e) => {
                              this.onTextChange(row.id, e);
                            }}
                          ></textarea>
                          {messageID === row.id && (
                            <button type="submit" className="custom-btn br min">
                              {row.reply
                                ? translate.get("Edit")
                                : translate.get("AnswerIt")}
                            </button>
                          )}
                        </form>
                      ) : (
                        <div>
                          <b>{translate.get("Answer")}</b>
                          <p>{row.reply}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              {isScrollLoading ? <ScrollLoader /> : null}
            </>
          )}
        </div>
      </div>
    );
  }
}
