import React from "react";
import { Modal } from "react-bootstrap";
import { translate } from "../../lib";

export const Popup = ({ children, show, title, size, onClose }) => {
  return (
    <Modal size={size} centered show={show} onHide={onClose} className="popup">
      <Modal.Header>
        <div className="d-flex justify-content-between align-items-center w-100">
          <h5 className="mb-0">{translate.get(title)}</h5>
          <button className="close-btn feather feather-x" onClick={onClose} />
        </div>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};
