import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Password } from "../layouts";

export class PasswordContainer extends React.Component {
  render() {
    return (
        <div className='content' >
          <Switch>
            <Route exact path="/password" component={Password} />
            <Redirect to="/" />
          </Switch>
        </div>
    );
  }
}
