import React from "react";
import { carsTechnicalHistory } from "../../actions";
import { toast } from "../../lib";
import { ComponentLoader, Loading } from "../../components";

export const Technical = ({ id, onClose = () => {} }) => {
  const [state, setState] = React.useReducer(
    (prevState, newState) => ({ ...prevState, ...newState }),
    {
      loading: false,
      data: [],
    }
  );
  const loadData = async () => {
    setState({ loading: true });
    let response = await carsTechnicalHistory({ id, limit: 100 });
    if (response) {
      if (response.status === "success") {
        setState({ loading: false });
        setState({ data: response.data });
      } else {
        onClose();
        setTimeout(() => {
          toast.fire({
            icon: response.status,
            title: response.description,
          });
        }, 200);
      }
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <div
      className="technical_list"
      ref={(el) => {
        if (el) {
          el.parentElement.style.padding = "0";
        }
      }}
    >
      {state.loading && (
        <div className="py-5">
          <ComponentLoader />
        </div>
      )}
      {state.data.map((row, key) => (
        <React.Fragment>
          <div className="item" key={key}>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center">
                <Icon />
              </div>
              <div className="date">{row.date}</div>
            </div>
            <div className="description">{row.description}</div>
          </div>
          {state.data.length - 1 !== key && (
            <div className="technical_list-divider" />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

const Icon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.42651 8.96791C6.85848 4.65597 8.07446 2.5 10.0001 2.5C11.9257 2.5 13.1417 4.65597 15.5737 8.96791L15.8767 9.50522C17.8977 13.0884 18.9081 14.88 17.9949 16.19C17.0816 17.5 14.8221 17.5 10.3031 17.5H9.69703C5.17805 17.5 2.91856 17.5 2.0053 16.19C1.09203 14.88 2.10251 13.0884 4.12346 9.50523L4.42651 8.96791ZM10.0001 6.04167C10.3453 6.04167 10.6251 6.32149 10.6251 6.66667V10.8333C10.6251 11.1785 10.3453 11.4583 10.0001 11.4583C9.6549 11.4583 9.37508 11.1785 9.37508 10.8333V6.66667C9.37508 6.32149 9.6549 6.04167 10.0001 6.04167ZM10.0001 14.1667C10.4603 14.1667 10.8334 13.7936 10.8334 13.3333C10.8334 12.8731 10.4603 12.5 10.0001 12.5C9.53984 12.5 9.16675 12.8731 9.16675 13.3333C9.16675 13.7936 9.53984 14.1667 10.0001 14.1667Z"
        fill="#FFB800"
      />
    </svg>
  );
};
